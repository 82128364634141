export const FETCH_BOARD = 'FETCH_BOARD'
export const FETCH_FAQ = 'FETCH_FAQ'
export const FETCH_SET_NOTICE_POPUP = 'FETCH_SET_NOTICE_POPUP'
export const FETCH_SET_NOTICE_ADMIN = 'FETCH_SET_NOTICE_ADMIN'
export const FETCH_SET_NOTICE_ADMIN_CNT = 'FETCH_SET_NOTICE_ADMIN_CNT'
export const FETCH_REQUEST_ALARMS = 'FETCH_REQUEST_ALARMS'
export const FETCH_REQUEST_ALARMS_FINISH = 'FETCH_REQUEST_ALARMS_FINISH'
export const FETCH_REQUEST_ALARMS_CNT = 'FETCH_REQUEST_ALARMS_CNT'
export const FETCH_DEPOSIT_SMPL = 'FETCH_DEPOSIT_SMPL'
export const FETCH_REQUEST_ALARMS_SIGNUP = 'FETCH_REQUEST_ALARMS_SIGNUP'
export const FETCH_REQUEST_ALARMS_SIGNUP_FINISH = 'FETCH_REQUEST_ALARMS_SIGNUP_FINISH'
export const FETCH_REQUEST_ALARMS_SIGNUP_CNT = 'FETCH_REQUEST_ALARMS_SIGNUP_CNT'
export const FETCH_REQUEST_ALARMS_BET = 'FETCH_REQUEST_ALARMS_BET'
export const FETCH_REQUEST_ALARMS_BET_FINISH = 'FETCH_REQUEST_ALARMS_BET_FINISH'
export const FETCH_REQUEST_ALARMS_BET_ALL = 'FETCH_REQUEST_ALARMS_BET_ALL'
export const FETCH_REQUEST_ALARMS_BET_CNT = 'FETCH_REQUEST_ALARMS_BET_CNT'
export const FETCH_REQUEST_ALARMS_DEPOSIT = 'FETCH_REQUEST_ALARMS_DEPOSIT'
export const FETCH_REQUEST_ALARMS_DEPOSIT_FINISH = 'FETCH_REQUEST_ALARMS_DEPOSIT_FINISH'
export const FETCH_REQUEST_ALARMS_DEPOSIT_CNT = 'FETCH_REQUEST_ALARMS_DEPOSIT_CNT'
export const FETCH_REQUEST_ALARMS_EXCHANGE = 'FETCH_REQUEST_ALARMS_EXCHANGE'
export const FETCH_REQUEST_ALARMS_EXCHANGE_FINISH = 'FETCH_REQUEST_ALARMS_EXCHANGE_FINISH'
export const FETCH_REQUEST_ALARMS_EXCHANGE_CNT = 'FETCH_REQUEST_ALARMS_EXCHANGE_CNT'
export const FETCH_REQUEST_ALARMS_BRANCH_CASHOUT = 'FETCH_REQUEST_ALARMS_BRANCH_CASHOUT'
export const FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH = 'FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH'
export const FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT = 'FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT'
