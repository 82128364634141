import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  FETCH_USER_BET_HISTORY,
  FETCH_USER_BET_HISTORY_CONFIRM,
  FETCH_BETTING_LIST_BY_MATCH,
  FETCH_BETTING_LIST_BY_MATCH_DETAIL,
  FETCH_GAME_RESULTS_SOCCER,
  FETCH_GAME_RESULTS_BASKETBALL,
  FETCH_GAME_RESULTS_BASEBALL,
  FETCH_GAME_RESULTS_TENNIS,
  FETCH_GAME_RESULTS_VOLLEYBALL,
  FETCH_GAME_RESULTS_FOOTBALL,
  FETCH_GAME_RESULTS_HOCKEY,
  FETCH_GAME_RESULTS_ESPORTS,
} from '@/store/result/action'
import {
  UPDATE_MATCH_CUSTOM_TIE,
  UPDATE_GAME_CUSTOM_CANCEL,
  UPDATE_MANY_GAME_CONFIRM,
  UPDATE_MANY_GAME_REJECT,
  UPDATE_GAME_RESULTS_CUSTOM,
  CLEAR_GAME_RESULT_CUSTOM,
} from '@/store/result/mutation'

const resultStore = {
  namespaced: true,
  state: {
    clientFetchUserBetHistory: [],
    clientFetchUserBetHistoryConfirm: [],
    updateMatchCustomTie: [],
    updateGameCustomCancel: [],
    updateManyGameConfirm: [],
    updateManyGameReject: [],
    updateGameResultsCustom: [],
    fetchBettingListByMatch: [],
    fetchBettingListByMatchDetail: [],
    fetchGameResultsSoccer: [],
    fetchGameResultsBasketball: [],
    fetchGameResultsBaseball: [],
    fetchGameResultsTennis: [],
    fetchGameResultsVolleyball: [],
    fetchGameResultsFootball: [],
    fetchGameResultsHockey: [],
    fetchGameResultsEsports: [],
  },
  getters: {
    // getField,
    clientFetchUserBetHistory: state => state.clientFetchUserBetHistory,
    clientFetchUserBetHistoryConfirm: state => state.clientFetchUserBetHistoryConfirm,
    updateMatchCustomTie: state => state.updateMatchCustomTie,
    updateGameCustomCancel: state => state.updateGameCustomCancel,
    updateManyGameConfirm: state => state.updateManyGameConfirm,
    updateManyGameReject: state => state.updateManyGameReject,
    updateGameResultsCustom: state => state.updateGameResultsCustom,
    fetchBettingListByMatch: state => state.fetchBettingListByMatch,
    fetchBettingListByMatchDetail: state => state.fetchBettingListByMatchDetail,
    fetchGameResultsSoccer: state => state.fetchGameResultsSoccer,
    fetchGameResultsBasketball: state => state.fetchGameResultsBasketball,
    fetchGameResultsBaseball: state => state.fetchGameResultsBaseball,
    fetchGameResultsTennis: state => state.fetchGameResultsTennis,
    fetchGameResultsVolleyball: state => state.fetchGameResultsVolleyball,
    fetchGameResultsFootball: state => state.fetchGameResultsFootball,
    fetchGameResultsHockey: state => state.fetchGameResultsHockey,
    fetchGameResultsEsports: state => state.fetchGameResultsEsports,
  },
  mutations: {
    // updateField,
    [FETCH_USER_BET_HISTORY](state, clientFetchUserBetHistory) {
      state.clientFetchUserBetHistory = clientFetchUserBetHistory
    },
    [FETCH_USER_BET_HISTORY_CONFIRM](state, clientFetchUserBetHistoryConfirm) {
      state.clientFetchUserBetHistoryConfirm = clientFetchUserBetHistoryConfirm
    },
    [UPDATE_MATCH_CUSTOM_TIE](state, updateMatchCustomTie) {
      state.updateMatchCustomTie = updateMatchCustomTie
    },
    [UPDATE_GAME_CUSTOM_CANCEL](state, updateGameCustomCancel) {
      state.updateGameCustomCancel = updateGameCustomCancel
    },
    [UPDATE_MANY_GAME_CONFIRM](state, updateManyGameConfirm) {
      state.updateManyGameConfirm = updateManyGameConfirm
    },
    [UPDATE_MANY_GAME_REJECT](state, updateManyGameReject) {
      state.updateManyGameReject = updateManyGameReject
    },
    [UPDATE_GAME_RESULTS_CUSTOM](state, updateGameResultsCustom) {
      state.updateGameResultsCustom = updateGameResultsCustom
    },
    [FETCH_BETTING_LIST_BY_MATCH](state, fetchBettingListByMatch) {
      state.fetchBettingListByMatch = fetchBettingListByMatch
    },
    [FETCH_BETTING_LIST_BY_MATCH_DETAIL](state, fetchBettingListByMatchDetail) {
      state.fetchBettingListByMatchDetail = fetchBettingListByMatchDetail
    },
    [FETCH_GAME_RESULTS_SOCCER](state, fetchGameResultsSoccer) {
      state.fetchGameResultsSoccer = fetchGameResultsSoccer
    },
    [FETCH_GAME_RESULTS_BASKETBALL](state, fetchGameResultsBasketball) {
      state.fetchGameResultsBasketball = fetchGameResultsBasketball
    },
    [FETCH_GAME_RESULTS_BASEBALL](state, fetchGameResultsBaseball) {
      state.fetchGameResultsBaseball = fetchGameResultsBaseball
    },
    [FETCH_GAME_RESULTS_TENNIS](state, fetchGameResultsTennis) {
      state.fetchGameResultsTennis = fetchGameResultsTennis
    },
    [FETCH_GAME_RESULTS_VOLLEYBALL](state, fetchGameResultsVolleyball) {
      state.fetchGameResultsVolleyball = fetchGameResultsVolleyball
    },
    [FETCH_GAME_RESULTS_FOOTBALL](state, fetchGameResultsFootball) {
      state.fetchGameResultsFootball = fetchGameResultsFootball
    },
    [FETCH_GAME_RESULTS_HOCKEY](state, fetchGameResultsHockey) {
      state.fetchGameResultsHockey = fetchGameResultsHockey
    },
    [FETCH_GAME_RESULTS_ESPORTS](state, fetchGameResultsEsports) {
      state.fetchGameResultsEsports = fetchGameResultsEsports
    },
    [CLEAR_GAME_RESULT_CUSTOM](state) {
      state.updateGameResultsCustom = []
    },
    // @input="updateParam('fetchGameResultsSoccer','awayTeamCustomResult', $event, data.item.idx)"
    updateManyParam(state, events) {
      // console.log('t >> ', events.t) // target
      // console.log('f >> ', events.f) // from target
      // console.log('e >> ', events.e) // event
      // console.log('v >> ', events.v) // value
      // console.log('i >> ', events.i) // idx
      // state[events.t][events.i][events.e] = events.v

      const targetdIdx = state[events.t].findIndex(item => item.idx === events.i)
      if (targetdIdx !== -1) {
        state[events.t][targetdIdx][events.e] = events.v
      } else {
        const obj = state[events.f].find(item => item.idx === events.i)
        obj[events.e] = events.v
        state[events.t].push(obj)
      }
    },
  },
  actions: {
    async [FETCH_USER_BET_HISTORY]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
            query clientFetchUserBetHistory($site: String, $userid: String) {
                clientFetchUserBetHistory(site: $site, userid: $userid) {
                    idx
                    site
                    userid
                    category
                    sports
                    gameId
                    gameDate
                    leagueName
                    homeName
                    awayName
                    oddsType
                    oddsId
                    odds
                    handicap
                    homeAway
                    groupId
                    seq
                    betCash
                    homeTeamResult
                    awayTeamResult
                    status
                    betResult
                    hitCash
                    totalBetResult
                    totalOdds
                    totalHitCash
                    confirmed
                    createdAt
                    updatedAt
                }
            }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_USER_BET_HISTORY, data.clientFetchUserBetHistory)
      console.log('FETCH_USER_BET_HISTORY : ', data.clientFetchUserBetHistory)
    },
    async [FETCH_USER_BET_HISTORY_CONFIRM]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query clientFetchUserBetHistoryConfirm($site: String, $userid: String, $groupId: String) {
                      clientFetchUserBetHistoryConfirm(site: $site, userid: $userid, groupId: $groupId) {
                          idx
                          site
                          userid
                          nickname
                          category
                          sports
                          gameId
                          gameDate
                          leagueName
                          homeName
                          awayName
                          oddsType
                          oddsId
                          oddsHome
                          oddsDraw
                          oddsAway
                          odds
                          handicap
                          homeAway
                          groupId
                          seq
                          betCash
                          homeTeamResult
                          awayTeamResult
                          status
                          betResult
                          hitCash
                          totalBetResult
                          totalOdds
                          totalHitCash
                          confirmed
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          groupId: payload.groupId,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_USER_BET_HISTORY_CONFIRM, data.clientFetchUserBetHistoryConfirm)
      console.log('FETCH_USER_BET_HISTORY_CONFIRM : ', data.clientFetchUserBetHistoryConfirm)
    },

    // 개별 적중특례 설정
    async [UPDATE_MATCH_CUSTOM_TIE]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
              mutation updateMatchCustomTie($userBetHistoryUpdateArgs: UserBetHistoryUpdateArgs!, $ip: String!, $userid: String!){
                  updateMatchCustomTie(userBetHistoryUpdateArgs: $userBetHistoryUpdateArgs, ip: $ip, userid: $userid) {
                      idx
                  }
              }
          `,
        variables: {
          userBetHistoryUpdateArgs: {
            idx: Number(payload.idx),
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          },
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MATCH_CUSTOM_TIE, data.updateMatchCustomTie)
      console.log('UPDATE_MATCH_CUSTOM_TIE : ', data.updateMatchCustomTie)
    },

    // 배팅 취소 처리
    async [UPDATE_GAME_CUSTOM_CANCEL]({ commit, state }, payload) {
      console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
              mutation updateGameCustomCancel($requestAlarmsBetInputArgs: RequestAlarmsBetInputArgs!, $ip: String!, $userid: String!){
                  updateGameCustomCancel(requestAlarmsBetInputArgs: $requestAlarmsBetInputArgs, ip: $ip, userid: $userid) {
                      groupId
                  }
              }
          `,
        variables: {
          requestAlarmsBetInputArgs: {
            groupId: payload.groupId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          },
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_CUSTOM_CANCEL, data.updateGameCustomCancel)
      console.log('UPDATE_GAME_CUSTOM_CANCEL : ', data.updateGameCustomCancel)
    },

    // 환급 확정 승인 처리
    async [UPDATE_MANY_GAME_CONFIRM]({ commit, state }, payload) {
      console.log('@@ : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyGameConfirm($requestAlarmsBetInputArgs: [RequestAlarmsBetInputArgs!]!, $ip: String!, $userid: String!) {
            updateManyGameConfirm(requestAlarmsBetInputArgs: $requestAlarmsBetInputArgs, ip: $ip, userid: $userid) {
              groupId
            }
          }
        `,
        variables: {
          requestAlarmsBetInputArgs: payload.groupId.map(groupId => ({
            groupId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_GAME_CONFIRM, data.updateManyGameConfirm)
      console.log('UPDATE_MANY_GAME_CONFIRM : ', data.updateManyGameConfirm)
    },

    // 환급 확정 부결 처리
    async [UPDATE_MANY_GAME_REJECT]({ commit, state }, payload) {
      console.log('@@ : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
           mutation updateManyGameReject($requestAlarmsBetInputArgs: [RequestAlarmsBetInputArgs!]!, $ip: String!, $userid: String!) {
               updateManyGameReject(requestAlarmsBetInputArgs: $requestAlarmsBetInputArgs, ip: $ip, userid: $userid) {
                   groupId
               }
           }
       `,
        variables: {
          requestAlarmsBetInputArgs: payload.groupId.map(groupId => ({
            groupId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_GAME_REJECT, data.updateManyGameReject)
      console.log('UPDATE_MANY_GAME_REJECT : ', data.updateManyGameReject)
    },

    // 경기별 배팅리스트 조회
    async [FETCH_BETTING_LIST_BY_MATCH]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
              query fetchBettingListByMatch($site: [String!], $periodFrom: String, $periodTo: String, $confirmed: String, $category: String) {
                  fetchBettingListByMatch(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, confirmed: $confirmed, category: $category) {
                    idx
                    site
                    userid
                    category
                    sports
                    gameId
                    gameDate
                    leagueName
                    homeName
                    awayName
                    oddsType
                    oddsId
                    oddsHome
                    oddsDraw
                    oddsAway
                    odds
                    handicap
                    homeAway
                    groupId
                    seq
                    betCash
                    homeTeamResult
                    awayTeamResult
                    status
                    betResult
                    count
                    betCashHome
                    betCashTie
                    betCashAway
                    betCashTotal
                    totalBetResult
                    totalOdds
                    totalHitCash
                    confirmed
                    createdAt
                    updatedAt
                }
            }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
          category: payload.category,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BETTING_LIST_BY_MATCH, data.fetchBettingListByMatch)
      console.log('FETCH_BETTING_LIST_BY_MATCH : ', data.fetchBettingListByMatch)
    },
    async [FETCH_BETTING_LIST_BY_MATCH_DETAIL]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
               query fetchBettingListByMatchDetail($site: [String!], $gameId: String, $oddsType: String, $homeAway: String, $oddsHome: Float, $oddsDraw: Float, $oddsAway: Float) {
                   fetchBettingListByMatchDetail(site: $site, gameId: $gameId, oddsType: $oddsType, homeAway: $homeAway, oddsHome: $oddsHome, oddsDraw: $oddsDraw, oddsAway: $oddsAway) {
                        idx
                        site
                        userid
                        nickname
                        category
                        sports
                        gameId
                        gameDate
                        leagueName
                        homeName
                        awayName
                        oddsType
                        oddsId
                        oddsHome
                        oddsDraw
                        oddsAway
                        odds
                        handicap
                        homeAway
                        groupId
                        seq
                        betCash
                        homeTeamResult
                        awayTeamResult
                        status
                        betResult
                        count
                        betCashHome
                        betCashTie
                        betCashAway
                        betCashTotal
                        totalBetResult
                        totalOdds
                        totalHitCash
                        confirmed
                        createdAt
                     }
                 }
            `,
        variables: {
          site: payload.site,
          gameId: payload.gameId,
          oddsType: payload.oddsType,
          homeAway: payload.homeAway,
          oddsHome: Number(payload.oddsHome),
          oddsDraw: Number(payload.oddsDraw),
          oddsAway: Number(payload.oddsAway),
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BETTING_LIST_BY_MATCH_DETAIL, data.fetchBettingListByMatchDetail)
      console.log('FETCH_BETTING_LIST_BY_MATCH_DETAIL : ', data.fetchBettingListByMatchDetail)
    },
    // 경기 결과 (축구)
    async [FETCH_GAME_RESULTS_SOCCER]({ commit }, payload) {
      console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameResultsSoccer($site: String, $periodFrom: String, $periodTo: String, $searchOption: String, $inputValue: String) {
                      fetchGameResultsSoccer(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, searchOption: $searchOption, inputValue: $inputValue) {
                          idx
                          gameId
                          gameId2
                          gameDate
                          status
                          leagueId
                          leagueName
                          homeTeamName
                          awayTeamName
                          homeTeamResult
                          awayTeamResult
                          halfTimeResult
                          fullTimeResult
                          homeTeamCustomResult
                          awayTeamCustomResult
                          customResultYn
                          timer
                          injTime
                          commentary
                          heatmap
                          groupId
                          staticId
                          fixedId
                          venue
                          v
                          events
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_RESULTS_SOCCER, data.fetchGameResultsSoccer)
      console.log('FETCH_GAME_RESULTS_SOCCER : ', data.fetchGameResultsSoccer)
    },
    // 경기 결과 (농구)
    async [FETCH_GAME_RESULTS_BASKETBALL]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameResultsBasketball($site: String, $periodFrom: String, $periodTo: String, $searchOption: String, $inputValue: String) {
                      fetchGameResultsBasketball(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, searchOption: $searchOption, inputValue: $inputValue) {
                          idx
                          gameId
                          gameId2
                          gameDate
                          status
                          leagueId
                          leagueName
                          homeTeamName
                          awayTeamName
                          homeTeamResult
                          awayTeamResult
                          homeTeamCustomResult
                          awayTeamCustomResult
                          customResultYn
                          homeOt
                          homeQ1
                          homeQ2
                          homeQ3
                          homeQ4
                          awayOt
                          awayQ1
                          awayQ2
                          awayQ3
                          awayQ4
                          homeAssists
                          homeBlocks
                          homeFieldGoalsAttempts
                          homeFieldGoalsPct
                          homeFieldGoalsTotal
                          homeFreeThrowsAttempts
                          homeFreeThrowsPct
                          homeFreeThrowsTotal
                          homePersonalFouls
                          homeReboundsDefense
                          homeReboundsOffense
                          homeReboundsTotal
                          homeSteals
                          homeThreePointAttempts
                          homeThreePointPct
                          homeThreePointTotal
                          homeTurnovers
                          awayAssists
                          awayBlocks
                          awayFieldGoalsAttempts
                          awayFieldGoalsPct
                          awayFieldGoalsTotal
                          awayFreeThrowsAttempts
                          awayFreeThrowsPct
                          awayFreeThrowsTotal
                          awayPersonalFouls
                          awayReboundsDefense
                          awayReboundsOffense
                          awayReboundsTotal
                          awaySteals
                          awayThreePointAttempts
                          awayThreePointPct
                          awayThreePointTotal
                          awayTurnovers
                          homeStarters
                          homeBench
                          awayStarters
                          awayBench
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_RESULTS_BASKETBALL, data.fetchGameResultsBasketball)
      console.log('FETCH_GAME_RESULTS_BASKETBALL : ', data.fetchGameResultsBasketball)
    },
    // 경기 결과 (야구)
    async [FETCH_GAME_RESULTS_BASEBALL]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameResultsBaseball($site: String, $periodFrom: String, $periodTo: String, $searchOption: String, $inputValue: String) {
                      fetchGameResultsBaseball(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, searchOption: $searchOption, inputValue: $inputValue) {
                          idx
                          gameId
                          gameId2
                          gameDate
                          status
                          extraInn
                          leagueId
                          leagueName
                          homeTeamName
                          awayTeamName
                          homeTeamResult
                          awayTeamResult
                          homeTeamCustomResult
                          awayTeamCustomResult
                          customResultYn
                          homeIn1
                          homeIn2
                          homeIn3
                          homeIn4
                          homeIn5
                          homeIn6
                          homeIn7
                          homeIn8
                          homeIn9
                          homeEx
                          homeHits
                          homeErrors
                          awayIn1
                          awayIn2
                          awayIn3
                          awayIn4
                          awayIn5
                          awayIn6
                          awayIn7
                          awayIn8
                          awayIn9
                          awayEx
                          awayHits
                          awayErrors
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_RESULTS_BASEBALL, data.fetchGameResultsBaseball)
      console.log('FETCH_GAME_RESULTS_BASEBALL : ', data.fetchGameResultsBaseball)
    },
    // 경기 결과 (테니스)
    async [FETCH_GAME_RESULTS_TENNIS]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameResultsTennis($site: String, $periodFrom: String, $periodTo: String, $searchOption: String, $inputValue: String) {
                      fetchGameResultsTennis(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, searchOption: $searchOption, inputValue: $inputValue) {
                          idx
                          gameId
                          gameId2
                          gameDate
                          status
                          tiebreak
                          leagueId
                          leagueName
                          homeTeamName
                          awayTeamName
                          homeTeamResult
                          awayTeamResult
                          homeTeamCustomResult
                          awayTeamCustomResult
                          customResultYn
                          homeServe
                          homeGameScore
                          homeGameCustomScore
                          homeSet1
                          homeSet2
                          homeSet3
                          homeSet4
                          homeSet5
                          homeWinner
                          awayServe
                          awayGameScore
                          awayGameCustomScore
                          awaySet1
                          awaySet2
                          awaySet3
                          awaySet4
                          awaySet5
                          awayWinner
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_RESULTS_TENNIS, data.fetchGameResultsTennis)
      console.log('FETCH_GAME_RESULTS_TENNIS : ', data.fetchGameResultsTennis)
    },
    // 경기 결과 (배구)
    async [FETCH_GAME_RESULTS_VOLLEYBALL]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameResultsVolleyball($site: String, $periodFrom: String, $periodTo: String, $searchOption: String, $inputValue: String) {
                      fetchGameResultsVolleyball(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, searchOption: $searchOption, inputValue: $inputValue) {
                          idx
                          gameId
                          gameId2
                          gameDate
                          status
                          leagueId
                          leagueName
                          homeTeamName
                          awayTeamName
                          homeTeamResult
                          awayTeamResult
                          homeTeamCustomResult
                          awayTeamCustomResult
                          customResultYn
                          homeS1
                          homeS2
                          homeS3
                          homeS4
                          homeS5
                          awayS1
                          awayS2
                          awayS3
                          awayS4
                          awayS5
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_RESULTS_VOLLEYBALL, data.fetchGameResultsVolleyball)
      console.log('FETCH_GAME_RESULTS_VOLLEYBALL : ', data.fetchGameResultsVolleyball)
    },
    // 경기 결과 (배구)
    async [FETCH_GAME_RESULTS_FOOTBALL]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameResultsFootball($site: String, $periodFrom: String, $periodTo: String, $searchOption: String, $inputValue: String) {
            fetchGameResultsFootball(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, searchOption: $searchOption, inputValue: $inputValue) {
              idx
              gameId
              gameId2
              gameDate
              status
              leagueId
              leagueName
              homeTeamName
              awayTeamName
              homeTeamResult
              awayTeamResult
              homeTeamCustomResult
              awayTeamCustomResult
              customResultYn
              events
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_RESULTS_FOOTBALL, data.fetchGameResultsFootball)
      console.log('FETCH_GAME_RESULTS_FOOTBALL : ', data.fetchGameResultsFootball)
    },
    // 경기 결과 (하키)
    async [FETCH_GAME_RESULTS_HOCKEY]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameResultsHockey($site: String, $periodFrom: String, $periodTo: String, $searchOption: String, $inputValue: String) {
                      fetchGameResultsHockey(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, searchOption: $searchOption, inputValue: $inputValue) {
                          idx
                          gameId
                          gameId2
                          gameDate
                          status
                          leagueId
                          leagueName
                          homeTeamName
                          awayTeamName
                          homeTeamResult
                          awayTeamResult
                          homeTeamCustomResult
                          awayTeamCustomResult
                          customResultYn
                          scoreP1
                          scoreP2
                          scoreP3
                          scoreOver
                          scorePen
                          eventP1
                          eventP2
                          eventP3
                          eventOver
                          eventPen
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_RESULTS_HOCKEY, data.fetchGameResultsHockey)
      console.log('FETCH_GAME_RESULTS_HOCKEY : ', data.fetchGameResultsHockey)
    },
    // 경기 결과 (이스포츠)
    async [FETCH_GAME_RESULTS_ESPORTS]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameResultsEsports($site: String, $periodFrom: String, $periodTo: String, $searchOption: String, $inputValue: String) {
                      fetchGameResultsEsports(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, searchOption: $searchOption, inputValue: $inputValue) {
                          idx
                          gameId
                          gameId2
                          gameDate
                          status
                          round
                          type
                          leagueId
                          leagueName
                          homeTeamName
                          awayTeamName
                          homeTeamResult
                          awayTeamResult
                          homeTeamCustomResult
                          awayTeamCustomResult
                          customResultYn
                          scoreBoard
                          games
                          lineups
                          maps
                          streams
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_RESULTS_ESPORTS, data.fetchGameResultsEsports)
      console.log('FETCH_GAME_RESULTS_ESPORTS : ', data.fetchGameResultsEsports)
    },

    async [UPDATE_GAME_RESULTS_CUSTOM]({ commit, state }, payload) {
      console.log('UPDATE_GAME_RESULTS : ', payload)
      try {
        const targetIdx = state.updateGameResultsCustom.findIndex(item => item.idx === payload.idx)
        console.log(targetIdx) // 이 줄이 출력되는지 확인

        if (targetIdx === '-1') throw new Error('no data...')

        const targetData = state.updateGameResultsCustom[targetIdx]

        const { data } = await apolloClient.mutate({
          mutation: gql`
            mutation upsertGameResultsCustom($gameResultsCustomInputArgs: GameResultsCustomInputArgs!) {
              upsertGameResultsCustom(gameResultsCustomInputArgs: $gameResultsCustomInputArgs) 
            }
          `,
          variables: {
            gameResultsCustomInputArgs: {
              site: payload.site,
              sports: payload.sports,
              gameId: targetData.gameId,
              gameId2: targetData.gameId2,
              gameDate: targetData.gameDate,
              status: targetData.status,
              leagueId: targetData.leagueId,
              leagueName: targetData.leagueName,
              homeTeamName: targetData.homeTeamName,
              awayTeamName: targetData.awayTeamName,
              homeTeamResult: targetData.homeTeamResult,
              awayTeamResult: targetData.awayTeamResult,
              homeTeamCustomResult: targetData.homeTeamCustomResult || null,
              awayTeamCustomResult: targetData.awayTeamCustomResult || null,
              homeGameCustomScore: targetData.homeGameCustomScore || null,
              awayGameCustomScore: targetData.awayGameCustomScore || null,
              customResultYn: targetData.customResultYn || 'Y',
            },
          },
          fetchPolicy: 'no-cache',
        })
        // commit(UPDATE_GAME_RESULTS_CUSTOM, data.upsertGameResultsCustom)
        console.log('UPDATE_GAME_RESULTS_CUSTOM : ', data.upsertGameResultsCustom)
      } catch (error) {
        console.error('Caught an error:', error.message)
      }
    },
  },

}

export default resultStore
