import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  users: [
    {
      id: 1,
      role: 'user',
      recommendNickname: '경남총판',
      nickname: '힘돈힘돈',
      username: '김진수',
      userid: 'zcso22',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 2,
      role: 'user',
      recommendNickname: '경남총판',
      nickname: '힘돈힘돈',
      username: '김진수',
      userid: 'zcso22',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 3,
      role: 'user',
      recommendNickname: '경남총판',
      nickname: '힘돈힘돈',
      username: '김진수',
      userid: 'zcso22',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 4,
      role: 'user',
      recommendNickname: '경남총판',
      nickname: '힘돈힘돈',
      username: '김진수',
      userid: 'zcso22',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 5,
      role: 'user',
      recommendNickname: '경남총판',
      nickname: '힘돈힘돈',
      username: '김진수',
      userid: 'zcso22',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 6,
      role: 'user',
      recommendNickname: '경남총판',
      nickname: '힘돈힘돈',
      username: '김진수',
      userid: 'zcso22',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 7,
      role: 'admin',
      recommendNickname: '부산총판',
      nickname: '부산대장',
      username: '정필돈',
      userid: '1502000wooo',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 8,
      role: 'user',
      recommendNickname: '부산총판',
      nickname: '돼지문신국밥충',
      username: '김팔수',
      userid: 'apsmm29142',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 9,
      role: 'user',
      recommendNickname: '부산총판',
      nickname: '돼지문신국밥충',
      username: '김팔수',
      userid: 'apsmm29142',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 10,
      role: 'user',
      recommendNickname: '부산총판',
      nickname: '돼지문신국밥충',
      username: '김팔수',
      userid: 'apsmm29142',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 11,
      role: 'user',
      recommendNickname: '부산총판',
      nickname: '돼지문신국밥충',
      username: '김팔수',
      userid: 'apsmm29142',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 12,
      role: 'user',
      recommendNickname: '부산총판',
      nickname: '돼지문신국밥충',
      username: '김팔수',
      userid: 'apsmm29142',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 13,
      role: 'user',
      recommendNickname: '부산총판',
      nickname: '돼지문신국밥충',
      username: '김팔수',
      userid: 'apsmm29142',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 14,
      role: 'user',
      recommendNickname: '부산총판',
      nickname: '돼지문신국밥충',
      username: '김팔수',
      userid: 'apsmm29142',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 15,
      role: 'user',
      recommendNickname: '부산총판',
      nickname: '돼지문신국밥충',
      username: '김팔수',
      userid: 'apsmm29142',
      mobile: '010-162-1002',
      rank: '6',
      cash: '2010000',
      point: '1550000',
      betTotal: '1660000',
      depositTotal: '1220000',
      exchangeTotal: '100000',
      logVisitTotal: '10',
      enrollDate: '2022-01-15',
      enrollIp: '192.10.155.111',
      logVisitLastDate: '2022-04-01',
      logVisitLastIp: '192.10.222.165',
      logVisitMethod: 'PC',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 16,
      role: 'user',
      recommendNickname: '강원총판',
      nickname: '김김김김',
      username: '김병조',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 17,
      role: 'user',
      recommendNickname: '강원총판',
      nickname: '김김김김',
      username: '김병조',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 18,
      role: 'user',
      recommendNickname: '강원총판',
      nickname: '김김김김',
      username: '김병조',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 19,
      role: 'user',
      recommendNickname: '강원총판',
      nickname: '김김김김',
      username: '김병조',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 20,
      role: 'user',
      recommendNickname: '강원총판',
      nickname: '김김김김',
      username: '김병조',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 21,
      role: 'user',
      recommendNickname: '강원총판',
      nickname: '김김김김',
      username: '김병조',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 22,
      role: 'user',
      recommendNickname: '강원총판',
      nickname: '김김김김',
      username: '김병조',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 23,
      role: 'branch',
      recommendNickname: '강원총판',
      nickname: '김김김김',
      username: '김병조',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 24,
      role: 'branch',
      recommendNickname: '구미총판',
      nickname: '난김지옥',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 25,
      role: 'branch',
      recommendNickname: '구미총판',
      nickname: '너와나랑한판',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 26,
      role: 'branch',
      recommendNickname: '구미총판',
      nickname: '도박충임',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'block',
    },
    {
      id: 27,
      role: 'user',
      recommendNickname: '구미총판',
      nickname: '마카오최',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 28,
      role: 'user',
      recommendNickname: '구미총판',
      nickname: '햄볶아요',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 29,
      role: 'user',
      recommendNickname: '구미총판',
      nickname: '난다손',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 30,
      role: 'user',
      recommendNickname: '구미총판',
      nickname: '난다손',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 31,
      role: 'user',
      recommendNickname: '구미총판',
      nickname: '난다손',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 32,
      role: 'admin',
      recommendNickname: '구미총판',
      nickname: '구미대장',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 33,
      role: 'user',
      recommendNickname: '구미총판',
      nickname: '난다손',
      username: '최병진',
      userid: 'apapwmenn',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 34,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '신의손길',
      username: '김병수',
      userid: '2012229aa',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 35,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '신의손길',
      username: '김병수',
      userid: '2012229aa',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 36,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '신의손길',
      username: '김병수',
      userid: '2012229aa',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 37,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '신의손길',
      username: '김병수',
      userid: '2012229aa',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 38,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '신의손길',
      username: '김병수',
      userid: '2012229aa',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 39,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '신의손길',
      username: '김병수',
      userid: '2012229aa',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 40,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '신의손길',
      username: '김병수',
      userid: '2012229aa',
      mobile: '010-066-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 41,
      role: 'admin',
      recommendNickname: '대구총판',
      nickname: '대구대장',
      username: '장주호',
      userid: 'apspppss',
      mobile: '010-022-7717',
      rank: '99',
      cash: '202000',
      point: '60000',
      betTotal: '320000',
      depositTotal: '230000',
      exchangeTotal: '16600000',
      logVisitTotal: '77',
      enrollDate: '2022-01-12',
      enrollIp: '192.10.155.122',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.155.22',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'aooooo@naver.com',
      status: 'active',
    },
    {
      id: 42,
      role: 'user',
      recommendNickname: '대구총판',
      nickname: '마이다스의손',
      username: '조상윤',
      userid: 'pajjj23',
      mobile: '010-102-0222',
      rank: '3',
      cash: '202000',
      point: '60000',
      betTotal: '320000',
      depositTotal: '230000',
      exchangeTotal: '16600000',
      logVisitTotal: '15',
      enrollDate: '2022-03-12',
      enrollIp: '192.10.155.122',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.155.22',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'aooooo@naver.com',
      status: 'active',
    },
    {
      id: 43,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '놀래미',
      username: '김진환',
      userid: 'paj1sooo',
      mobile: '010-102-9297',
      rank: '4',
      cash: '202000',
      point: '60000',
      betTotal: '320000',
      depositTotal: '230000',
      exchangeTotal: '1400000',
      logVisitTotal: '19',
      enrollDate: '2022-06-16',
      enrollIp: '192.10.155.155',
      logVisitLastDate: '2022-06-30',
      logVisitLastIp: '192.10.155.22',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'aooooo@naver.com',
      status: 'human',
    },
    {
      id: 44,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '마이다스의손',
      username: '조상윤',
      userid: 'pajjj23',
      mobile: '010-1023-0222',
      rank: '3',
      cash: '202000',
      point: '60000',
      betTotal: '320000',
      depositTotal: '230000',
      exchangeTotal: '16600000',
      logVisitTotal: '15',
      enrollDate: '2022-03-12',
      enrollIp: '192.10.155.122',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.155.22',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'aooooo@naver.com',
      status: 'active',
    },
    {
      id: 45,
      role: 'user',
      recommendNickname: '파주총판',
      nickname: '너와나',
      username: '이길수',
      userid: 'paj4441',
      mobile: '010-1022-1515',
      rank: '5',
      cash: '502000',
      point: '50000',
      betTotal: '120000',
      depositTotal: '250000',
      exchangeTotal: '1100000',
      logVisitTotal: '17',
      enrollDate: '2022-01-12',
      enrollIp: '192.10.155.122',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.155.22',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'gogo123@naver.com',
      status: 'active',
    },
    {
      id: 46,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '마이다스의손',
      username: '조상윤',
      userid: 'pajjj23',
      mobile: '010-1021-0222',
      rank: '3',
      cash: '202000',
      point: '60000',
      betTotal: '320000',
      depositTotal: '230000',
      exchangeTotal: '16600000',
      logVisitTotal: '15',
      enrollDate: '2022-03-12',
      enrollIp: '192.10.155.122',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.155.22',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'aooooo@naver.com',
      status: 'active',
    },
    {
      id: 47,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '마이다스의손',
      username: '조상윤',
      userid: 'pajjj23',
      mobile: '010-1402-0222',
      rank: '3',
      cash: '202000',
      point: '60000',
      betTotal: '320000',
      depositTotal: '230000',
      exchangeTotal: '16600000',
      logVisitTotal: '15',
      enrollDate: '2022-03-12',
      enrollIp: '192.10.155.122',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.155.22',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'aooooo@naver.com',
      status: 'active',
    },
    {
      id: 48,
      role: 'user',
      recommendNickname: '일산총판',
      nickname: '신의손길',
      username: '김병수',
      userid: '2012229aa',
      mobile: '010-0636-1155',
      rank: '3',
      cash: '1160000',
      point: '30000',
      betTotal: '200000',
      depositTotal: '20000',
      exchangeTotal: '1200000',
      logVisitTotal: '22',
      enrollDate: '2022-02-15',
      enrollIp: '192.10.155.177',
      logVisitLastDate: '2022-07-01',
      logVisitLastIp: '192.10.222.166',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'dodoni@naver.com',
      status: 'active',
    },
    {
      id: 49,
      role: 'admin',
      recommendNickname: '수원킹',
      nickname: '수원대장',
      username: '수원대장',
      userid: 'slsl123',
      mobile: '010-2295-1112',
      rank: '1',
      cash: '170000',
      point: '120000',
      betTotal: '1200000',
      depositTotal: '100000',
      exchangeTotal: '260000',
      logVisitTotal: '2',
      enrollDate: '2022-04-10',
      enrollIp: '192.10.155.121',
      logVisitLastDate: '2022-04-12',
      logVisitLastIp: '192.10.163.155',
      logVisitMethod: 'Mobile',
      country: 'Korea',
      email: 'asdwwww@akowkd.com',
      status: 'active',
    },
    {
      id: 50,
      role: 'user',
      recommendNickname: '수원킹',
      nickname: '개똥이',
      username: '홍길동',
      userid: 'perdonis',
      mobile: '010-2914-5153',
      rank: '5',
      cash: '700000',
      point: '700000',
      betTotal: '20500000',
      depositTotal: '1500000',
      exchangeTotal: '560000',
      logVisitTotal: '12',
      enrollDate: '2022-01-10',
      enrollIp: '192.10.122.196',
      logVisitLastDate: '2022-06-29',
      logVisitLastIp: '192.10.122.196',
      logVisitMethod: 'PC',
      country: 'China',
      email: 'bkrabbe1d@home.pl',
      status: 'active',
    },
    {
      id: 51,
      role: 'user',
      recommendNickname: '수원킹2',
      nickname: '개똥이1',
      username: '홍길동2',
      userid: 'perdonissss',
      mobile: '010-2924-5153',
      rank: '3',
      cash: '700000',
      point: '700000',
      betTotal: '20500000',
      depositTotal: '1500000',
      exchangeTotal: '560000',
      logVisitTotal: '12',
      enrollDate: '2022-01-10',
      enrollIp: '192.10.122.196',
      logVisitLastDate: '2022-06-29',
      logVisitLastIp: '192.10.122.196',
      logVisitMethod: 'PC',
      country: 'China',
      email: 'bkrabbe1d@home.pl',
      memo: '회원 메모입니다. 2022-10-20 회원출금시 문제가 있었음',
      status: 'active',
    },
  ],
}
// { key: 'id', sortable: true, label: '번호' },
// { key: 'recommendNickname', sortable: true, label: '상위/추천'},
// { key: 'nickname', sortable: true, label: '별명/@이름' },
// { key: 'userid', sortable: true, label: '아이디'},
// { key: 'mobile', sortable: true, label: '전화번호'},
// { key: 'rank', sortable: true, label: '회원등급'},
// { key: 'cash', sortable: true, label: '캐쉬'},
// { key: 'point', sortable: true, label: '포인트'},
// { key: 'betTotal', sortable: true, label: '총배팅금'},
// { key: 'depositTotal', sortable: true, label: '총충전금'},
// { key: 'exchangeTotal', sortable: true, label: '총환전금'},
// { key: 'logVisitTotal', sortable: true, label: '방문수'},
// { key: 'enrollDate', sortable: true, label: '가입일'},
// { key: 'enrollIp', sortable: true, label: '가입IP'},
// { key: 'logVisitLastDate', sortable: true, label: '최근접속일'},
// { key: 'logVisitLastIp', sortable: true, label: '최근접속IP'},
// { key: 'logVisitMethod', sortable: true, label: '접속유형'},
// { key: 'role', sortable: true },
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/user/users').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    role = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.users.filter(
    user =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (user.nickname.toLowerCase().includes(queryLowered)
          || user.username.toLowerCase().includes(queryLowered)
          || user.userid.toLowerCase().includes(queryLowered)
          || user.recommendNickname.toLowerCase().includes(queryLowered)
          || user.mobile.toLowerCase().includes(queryLowered)
      ) &&
      user.role === (role || user.role) &&
      user.status === (status || user.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/user/users').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)

  // Assign Status
  user.status = 'active'

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\d+/).reply(config => {
  // Get event id from URL
  let userid = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userid = Number(userid)

  const userIndex = data.users.findIndex(e => e.id === userid)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})
