export const UPSERT_BOARD = 'UPSERT_BOARD'
export const DELETE_BOARD = 'DELETE_BOARD'
export const UPSERT_FAQ = 'UPSERT_FAQ'
export const DELETE_FAQ = 'DELETE_FAQ'
export const UPSERT_SET_NOTICE_POPUP = 'UPSERT_SET_NOTICE_POPUP'
export const DELETE_SET_NOTICE_POPUP = 'DELETE_SET_NOTICE_POPUP'
export const UPSERT_SET_NOTICE_ADMIN = 'UPSERT_SET_NOTICE_ADMIN'
export const DELETE_SET_NOTICE_ADMIN = 'DELETE_SET_NOTICE_ADMIN'
export const UPDATE_SET_NOTICE_ADMIN_READ_AT = 'UPDATE_SET_NOTICE_ADMIN_READ_AT'
export const UPSERT_REQUEST_ALARMS = 'UPSERT_REQUEST_ALARMS'
export const UPSERT_DEPOSIT = 'UPSERT_DEPOSIT'
export const UPSERT_EXCHANGE = 'UPSERT_EXCHANGE'
export const UPDATE_USERS_CASH_POINT = 'UPDATE_USERS_CASH_POINT'
export const UPDATE_USER_SIGNUP_CONFIRM = 'UPDATE_USER_SIGNUP_CONFIRM'
export const UPDATE_USER_SIGNUP_REJECT = 'UPDATE_USER_SIGNUP_REJECT'
export const UPDATE_MANY_DEPOSIT_CONFIRM = 'UPDATE_MANY_DEPOSIT_CONFIRM'
export const UPDATE_MANY_DEPOSIT_REJECT = 'UPDATE_MANY_DEPOSIT_REJECT'
export const UPDATE_MANY_DEPOSIT_CANCEL = 'UPDATE_MANY_DEPOSIT_CANCEL'
export const UPDATE_MANY_EXCHANGE_CONFIRM = 'UPDATE_MANY_EXCHANGE_CONFIRM'
export const UPDATE_MANY_EXCHANGE_REJECT = 'UPDATE_MANY_EXCHANGE_REJECT'
export const UPDATE_MANY_EXCHANGE_CANCEL = 'UPDATE_MANY_EXCHANGE_CANCEL'
export const UPDATE_MANY_BRANCH_CASHOUT_CONFIRM = 'UPDATE_MANY_BRANCH_CASHOUT_CONFIRM'
export const UPDATE_MANY_BRANCH_CASHOUT_REJECT = 'UPDATE_MANY_BRANCH_CASHOUT_REJECT'
export const UPDATE_MANY_BRANCH_CASHOUT_CANCEL = 'UPDATE_MANY_BRANCH_CASHOUT_CANCEL'
