export const UPDATE_GAME_SET_BASIC = 'UPDATE_GAME_SET_BASIC'
export const UPDATE_GAME_SET_MENU = 'UPDATE_GAME_SET_MENU'
export const UPDATE_GAME_SET_ITEM = 'UPDATE_GAME_SET_ITEM'
export const UPDATE_GAME_SET_LEAGUE = 'UPDATE_GAME_SET_LEAGUE'
export const UPDATE_GAME_SET_TEAM = 'UPDATE_GAME_SET_TEAM'
export const CREATE_GAME_SET_TEAM = 'CREATE_GAME_SET_TEAM'
export const UPDATE_GAME_SET_TEAM_CUSTOM_NAME = 'UPDATE_GAME_SET_TEAM_CUSTOM_NAME'
export const UPDATE_GAME_SET_ODDS_MARKET = 'UPDATE_GAME_SET_ODDS_MARKET'
export const UPDATE_GAME_SET_ODDS_CUSTOM = 'UPDATE_GAME_SET_ODDS_CUSTOM'
export const UPDATE_GAME_SET_COMBINATION = 'UPDATE_GAME_SET_COMBINATION'
export const CREATE_MANY_CUSTOM_MATCH_BY_ADMIN = 'CREATE_MANY_CUSTOM_MATCH_BY_ADMIN'
export const UPDATE_CUSTOM_MATCH_BY_ADMIN = 'UPDATE_CUSTOM_MATCH_BY_ADMIN'
export const UPDATE_CUSTOM_RESULT_CONFIRM = 'UPDATE_CUSTOM_RESULT_CONFIRM'
export const UPSERT_GAME_SET_MATCH_BETTING_CUSTOM = 'UPSERT_GAME_SET_MATCH_BETTING_CUSTOM'
export const UPDATE_GAME_SET_VENDOR = 'UPDATE_GAME_SET_VENDOR'
