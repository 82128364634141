export default {
  namespaced: true,
  state: {
    selectedSiteIndex: 0,
    selectedSite: '',
  },
  getters: {
    setSelectedSiteIndex: state => state.setSelectedSiteIndex,
    setSelectedSite: state => state.setSelectedSite,
  },
  mutations: {
    setSelectedSiteIndex(state, newIndex) {
      state.selectedSiteIndex = newIndex
    },
    setSelectedSite(state, newSite) {
      state.selectedSite = newSite
    },
  },
}
