import router from '@/router'
import { SigninDocument } from '@/generated/graphql'
import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'
import {
  CHECK_EXPIRED_JWT, LOGIN, LOGOUT, CODE_CONFIRM,
} from './action'
import {
  SET_TOKEN, SET_USER, CLEAR_ALL, CLEAR_TOKEN, RE_SET_TOKEN, SET_MOBILE, RE_SET_USER, RE_SET_FACTOR,
} from './mutation'
import store from '@/store'

const AUTH_TOKEN_KEY = 'accessToken'
const AUTH_USER = 'userData'
const MOBILE = 'mobile'
const FACTOR = 'factor'
const AUTH_SITE = 'authSite'
const AUTH_SITE_SELECTED = 'authSiteSelected'
const USER_VIEW = 'userView'

export const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))

  return JSON.parse(jsonPayload)
}

const authStore = {
  namespaced: true,
  // TODO: init localstorage
  state: () => ({
    token: localStorage.getItem(AUTH_TOKEN_KEY),
    user: JSON.parse(localStorage.getItem(AUTH_USER)),
    mobile: localStorage.getItem(MOBILE),
    factor: localStorage.getItem(FACTOR),
  }),
  getters: {
    token: state => state.token,
    user: state => state.user,
    mobile: state => state.mobile,
    factor: state => state.factor,
    isAuthenticated: state => !!state.token,
    isUser: state => !!state.user,
    isMobile: state => !!state.mobile,
    isFactor: state => !!state.factor,
  },
  mutations: {
    [SET_TOKEN](state, token) {
      state.token = token
      // localStorage.setItem(AUTH_TOKEN_KEY, token)
    },
    [SET_USER](state, user) {
      state.user = user
      // localStorage.setItem(AUTH_USER, JSON.stringify(user))
    },
    [SET_MOBILE](state, mobile) {
      localStorage.setItem(MOBILE, mobile)
    },
    [CLEAR_ALL](state) {
      state.token = ''
      state.user = null
      state.mobile = ''
      state.factor = ''
      localStorage.removeItem(AUTH_TOKEN_KEY)
      localStorage.removeItem(AUTH_USER)
      localStorage.removeItem(MOBILE)
      localStorage.removeItem(FACTOR)
      localStorage.removeItem(AUTH_SITE)
      localStorage.removeItem(AUTH_SITE_SELECTED)
      localStorage.removeItem(USER_VIEW)
    },
    [CLEAR_TOKEN]() {
      localStorage.removeItem(AUTH_TOKEN_KEY)
    },
    [RE_SET_TOKEN](state) {
      localStorage.setItem(AUTH_TOKEN_KEY, state.token)
    },
    [RE_SET_USER](state) {
      localStorage.setItem(AUTH_USER, JSON.stringify(state.user))
    },
    [RE_SET_FACTOR](state) {
      localStorage.setItem(FACTOR, 'true')
      state.factor = 'true'
    },
    [AUTH_SITE](state, siteAdminInit) {
      localStorage.setItem(AUTH_SITE, siteAdminInit)
      localStorage.setItem('authSiteSelected', siteAdminInit[0])
    },
  },
  actions: {
    async [LOGIN]({ commit }, userData) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: gql`
              mutation($site: String!, $userid: String!, $password: String!) {
              signin(site: $site, userid: $userid, password: $password){
                token
                user {
                  id
                  site
                  userid
                  username
                  nickname
                  recommendNickname
                  email
                  mobile
                  cash
                  point
                  signinTotal
                  betTotal
                  depositTotal
                  exchangeTotal
                  rank
                  role
                  memo
                  enrollIp
                  enrollDate
                  status
                  password
                  outcomePassword
                  accountBank
                  accountNumber
                  updatedAt
                  createdAt
                }
              }
            }`,
          variables: {
            site: userData.site,
            userid: userData.userid,
            password: userData.password,
          },
        })
        if (data === undefined || data === null) {
          throw new Error('login fail')
        }
        // Admin 멀티 사이트 권한 설정
        const data2 = await apolloClient.query({
          query: gql`
              query fetchSetSiteAdminInit($userid: String) {
                  fetchSetSiteAdminInit(userid: $userid) {
                      idx
                      userid
                      authSite
                      enrollId
                      memo
                      createdAt
                      updatedAt
                  }
              }
          `,
          variables: {
            userid: userData.userid,
          },
          fetchPolicy: 'no-cache',
        })
        const siteAdminInit = data2.data.fetchSetSiteAdminInit.map(item => item.authSite)
        // console.log('@@@@@@@@ :::', siteAdminInit)
        if (siteAdminInit) {
          await commit(AUTH_SITE, siteAdminInit)
        }

        // 유저 권환 확인
        const { role } = data.signin.user
        // if (role === 'godfather') {
        //   const {token} = data.signin
        //   commit(SET_TOKEN, token)
        //   const user = parseJwt(token)
        //   localStorage.setItem(AUTH_USER, JSON.stringify(user))
        //   commit(SET_USER, user)
        //   localStorage.setItem(FACTOR, 'true')
        //   await router.replace(router.currentRoute.query.returnPath || '/')
        // }
        if (role === 'godfather' || role === 'admin' || role === 'branch') {
          const { token } = data.signin
          await commit(SET_TOKEN, token)

          const user = parseJwt(token)
          await commit(SET_USER, user)

          await commit(SET_MOBILE, user.mobile)

          // await commit(CLEAR_TOKEN)
          await router.replace('/login-2factor')

          //router.replace(router.currentRoute.query.returnPath || '/')
        } else {
          throw new Error('forbidden role')
          // return Promise.reject()
        }
      } catch (error) {
        console.log(error.message)
        // this.logger.error(error.message)
        throw error
      }
    },
    [CODE_CONFIRM]({ commit }) {
      console.log('confirm!!!!!')
      commit(RE_SET_FACTOR)
      commit(RE_SET_TOKEN)
      commit(RE_SET_USER)
      console.log(store.getters['authStore/isFactor'])
      console.log(store.getters['authStore/isAuthenticated'])
      console.log(store.getters['authStore/isUser'])
      router.replace(router.currentRoute.query.returnPath || '/')
    },
    [LOGOUT]({ commit }) {
      commit(CLEAR_ALL)
      router.replace('/login')
    },
    [CHECK_EXPIRED_JWT]({ dispatch, state }) {
      const expired = state.token ? parseJwt(state.token).exp < Date.now()/1000 : false;
      // console.log('parseJwt(state.token).exp > ', parseJwt(state.token).exp)
      // console.log('Date.now()/1000  > ', Date.now()/1000)

      // 만료시 로그아웃 합니다.
      if (expired) dispatch(LOGOUT)
    },
  },
}

export default authStore
