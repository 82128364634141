export const FETCH_USER_BET_HISTORY = 'FETCH_USER_BET_HISTORY'
export const FETCH_USER_BET_HISTORY_CONFIRM = 'FETCH_USER_BET_HISTORY_CONFIRM'
export const FETCH_BETTING_LIST_BY_MATCH = 'FETCH_BETTING_LIST_BY_MATCH'
export const FETCH_BETTING_LIST_BY_MATCH_DETAIL = 'FETCH_BETTING_LIST_BY_MATCH_DETAIL'
export const FETCH_GAME_RESULTS_SOCCER = 'FETCH_GAME_RESULTS_SOCCER'
export const FETCH_GAME_RESULTS_BASKETBALL = 'FETCH_GAME_RESULTS_BASKETBALL'
export const FETCH_GAME_RESULTS_BASEBALL = 'FETCH_GAME_RESULTS_BASEBALL'
export const FETCH_GAME_RESULTS_TENNIS = 'FETCH_GAME_RESULTS_TENNIS'
export const FETCH_GAME_RESULTS_VOLLEYBALL = 'FETCH_GAME_RESULTS_VOLLEYBALL'
export const FETCH_GAME_RESULTS_FOOTBALL = 'FETCH_GAME_RESULTS_FOOTBALL'
export const FETCH_GAME_RESULTS_HOCKEY = 'FETCH_GAME_RESULTS_HOCKEY'
export const FETCH_GAME_RESULTS_ESPORTS = 'FETCH_GAME_RESULTS_ESPORTS'
