import searchAndBookmarkData from '@core/layouts/components/app-navbar/search-and-bookmark-data'

const bookmarkState = {
  namespaced: true,
  state: {
    searchAndBookmarkData,
    bookmarks: searchAndBookmarkData.pages.data.filter(page => page.isBookmarked),
  },
  mutations: {
    toggleBookmarked: (state, item) => {
      // Find Index of item/page in bookmarks' array
      const { bookmarks } = state
      const pageIndexInBookmarks = bookmarks.findIndex(i => i.route === item.route)
      // console.log('pageIndexInBookmarks :::', pageIndexInBookmarks)

      // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
      // Else => Item is not bookmarked => Add item to bookmarks' array
      if (pageIndexInBookmarks > -1) {
        bookmarks[pageIndexInBookmarks].isBookmarked = false
        bookmarks.splice(pageIndexInBookmarks, 1)
      } else {
        bookmarks.push(item)
        bookmarks[bookmarks.length - 1].isBookmarked = true
      }
    },
  },
}

export default bookmarkState
