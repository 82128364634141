import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  FETCH_BOARD,
  FETCH_FAQ,
  FETCH_SET_NOTICE_POPUP,
  FETCH_SET_NOTICE_ADMIN,
  FETCH_SET_NOTICE_ADMIN_CNT,
  FETCH_REQUEST_ALARMS,
  FETCH_REQUEST_ALARMS_FINISH,
  FETCH_REQUEST_ALARMS_CNT,
  FETCH_DEPOSIT_SMPL,
  FETCH_REQUEST_ALARMS_SIGNUP,
  FETCH_REQUEST_ALARMS_SIGNUP_FINISH,
  FETCH_REQUEST_ALARMS_SIGNUP_CNT,
  FETCH_REQUEST_ALARMS_BET,
  FETCH_REQUEST_ALARMS_BET_FINISH,
  FETCH_REQUEST_ALARMS_BET_ALL,
  FETCH_REQUEST_ALARMS_BET_CNT,
  FETCH_REQUEST_ALARMS_DEPOSIT,
  FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
  FETCH_REQUEST_ALARMS_DEPOSIT_CNT,
  FETCH_REQUEST_ALARMS_EXCHANGE,
  FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
  FETCH_REQUEST_ALARMS_EXCHANGE_CNT,
  FETCH_REQUEST_ALARMS_BRANCH_CASHOUT,
  FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH,
  FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT,
} from '@/store/notice/action'
import {
  DELETE_BOARD,
  DELETE_FAQ,
  DELETE_SET_NOTICE_POPUP,
  UPSERT_BOARD,
  UPSERT_FAQ,
  UPSERT_SET_NOTICE_POPUP,
  UPSERT_SET_NOTICE_ADMIN,
  DELETE_SET_NOTICE_ADMIN,
  UPDATE_SET_NOTICE_ADMIN_READ_AT,
  UPSERT_REQUEST_ALARMS,
  UPSERT_DEPOSIT,
  UPSERT_EXCHANGE,
  UPDATE_USERS_CASH_POINT,
  UPDATE_USER_SIGNUP_CONFIRM,
  UPDATE_USER_SIGNUP_REJECT,
  UPDATE_MANY_DEPOSIT_CONFIRM,
  UPDATE_MANY_DEPOSIT_REJECT,
  UPDATE_MANY_DEPOSIT_CANCEL,
  UPDATE_MANY_EXCHANGE_CONFIRM,
  UPDATE_MANY_EXCHANGE_REJECT,
  UPDATE_MANY_EXCHANGE_CANCEL,
  UPDATE_MANY_BRANCH_CASHOUT_CONFIRM,
  UPDATE_MANY_BRANCH_CASHOUT_REJECT,
  UPDATE_MANY_BRANCH_CASHOUT_CANCEL,
} from '@/store/notice/mutation'

const noticeStore = {
  namespaced: true,
  state: {
    fetchBoard: [],
    upsertBoard: [],
    deleteBoard: [],
    fetchFaq: [],
    upsertFaq: [],
    deleteFaq: [],
    fetchSetNoticePopup: [],
    upsertSetNoticePopup: [],
    deleteSetNoticePopup: [],
    fetchSetNoticeAdmin: [],
    upsertSetNoticeAdmin: [],
    deleteSetNoticeAdmin: [],
    fetchSetNoticeAdminCnt: [],
    updateSetNoticeAdminReadAt: [],
    fetchRequestAlarms: [],
    fetchRequestAlarmsFinish: [],
    fetchRequestAlarmsCnt: [],
    upsertRequestAlarms: [],
    upsertDeposit: [],
    upsertExchange: [],
    fetchDepositSmpl: [],
    updateUsersCashPoint: [],
    fetchRequestAlarmsSignup: [],
    fetchRequestAlarmsSignupFinish: [],
    fetchRequestAlarmsSignupCnt: [],
    fetchRequestAlarmsBet: [],
    fetchRequestAlarmsBetFinish: [],
    fetchRequestAlarmsBetAll: [],
    fetchRequestAlarmsBetCnt: [],
    fetchRequestAlarmsDeposit: [],
    fetchRequestAlarmsDepositFinish: [],
    fetchRequestAlarmsDepositCnt: [],
    fetchRequestAlarmsExchange: [],
    fetchRequestAlarmsExchangeFinish: [],
    fetchRequestAlarmsExchangeCnt: [],
    fetchRequestAlarmsBranchCashout: [],
    fetchRequestAlarmsBranchCashoutFinish: [],
    fetchRequestAlarmsBranchCashoutCnt: [],
    updateUserSignupConfirm: [],
    updateUserSignupReject: [],
    updateManyDepositConfirm: [],
    updateManyDepositReject: [],
    updateManyDepositCancel: [],
    updateManyExchangeConfirm: [],
    updateManyExchangeReject: [],
    updateManyExchangeCancel: [],
    updateManyBranchCashoutConfirm: [],
    updateManyBranchCashoutReject: [],
    updateManyBranchCashoutCancel: [],
  },
  getters: {
    // getField,
    fetchBoard: state => state.fetchBoard,
    upsertBoard: state => state.upsertBoard,
    deleteBoard: state => state.deleteBoard,
    fetchFaq: state => state.fetchFaq,
    upsertFaq: state => state.upsertFaq,
    deleteFaq: state => state.deleteFaq,
    fetchSetNoticePopup: state => state.fetchSetNoticePopup,
    upsertSetNoticePopup: state => state.upsertSetNoticePopup,
    deleteSetNoticePopup: state => state.deleteSetNoticePopup,
    fetchSetNoticeAdmin: state => state.fetchSetNoticeAdmin,
    upsertSetNoticeAdmin: state => state.upsertSetNoticeAdmin,
    deleteSetNoticeAdmin: state => state.deleteSetNoticeAdmin,
    fetchSetNoticeAdminCnt: state => state.fetchSetNoticeAdminCnt,
    updateSetNoticeAdminReadAt: state => state.updateSetNoticeAdminReadAt,
    fetchRequestAlarms: state => state.fetchRequestAlarms,
    fetchRequestAlarmsFinish: state => state.fetchRequestAlarmsFinish,
    fetchRequestAlarmsCnt: state => state.fetchRequestAlarmsCnt,
    upsertRequestAlarms: state => state.upsertRequestAlarms,
    upsertDeposit: state => state.upsertDeposit,
    upsertExchange: state => state.upsertExchange,
    fetchDepositSmpl: state => state.fetchDepositSmpl,
    updateUsersCashPoint: state => state.updateUsersCashPoint,
    fetchRequestAlarmsSignup: state => state.fetchRequestAlarmsSignup,
    fetchRequestAlarmsSignupFinish: state => state.fetchRequestAlarmsSignupFinish,
    fetchRequestAlarmsSignupCnt: state => state.fetchRequestAlarmsSignupCnt,
    fetchRequestAlarmsBet: state => state.fetchRequestAlarmsBet,
    fetchRequestAlarmsBetFinish: state => state.fetchRequestAlarmsBetFinish,
    fetchRequestAlarmsBetAll: state => state.fetchRequestAlarmsBetAll,
    fetchRequestAlarmsBetCnt: state => state.fetchRequestAlarmsBetCnt,
    fetchRequestAlarmsDeposit: state => state.fetchRequestAlarmsDeposit,
    fetchRequestAlarmsDepositFinish: state => state.fetchRequestAlarmsDepositFinish,
    fetchRequestAlarmsDepositCnt: state => state.fetchRequestAlarmsDepositCnt,
    fetchRequestAlarmsExchange: state => state.fetchRequestAlarmsExchange,
    fetchRequestAlarmsExchangeFinish: state => state.fetchRequestAlarmsExchangeFinish,
    fetchRequestAlarmsExchangeCnt: state => state.fetchRequestAlarmsExchangeCnt,
    fetchRequestAlarmsBranchCashout: state => state.fetchRequestAlarmsBranchCashout,
    fetchRequestAlarmsBranchCashoutFinish: state => state.fetchRequestAlarmsBranchCashoutFinish,
    fetchRequestAlarmsBranchCashoutCnt: state => state.fetchRequestAlarmsBranchCashoutCnt,
    updateUserSignupConfirm: state => state.updateUserSignupConfirm,
    updateUserSignupReject: state => state.updateUserSignupReject,
    updateManyDepositConfirm: state => state.updateManyDepositConfirm,
    updateManyDepositReject: state => state.updateManyDepositReject,
    updateManyDepositCancel: state => state.updateManyDepositCancel,
    updateManyExchangeConfirm: state => state.updateManyExchangeConfirm,
    updateManyExchangeReject: state => state.updateManyExchangeReject,
    updateManyExchangeCancel: state => state.updateManyExchangeCancel,
    updateManyBranchCashoutConfirm: state => state.updateManyBranchCashoutConfirm,
    updateManyBranchCashoutReject: state => state.updateManyBranchCashoutReject,
    updateManyBranchCashoutCancel: state => state.updateManyBranchCashoutCancel,
  },
  mutations: {
    // updateField,
    [FETCH_BOARD](state, fetchBoard) {
      state.fetchBoard = fetchBoard
    },
    [UPSERT_BOARD](state, upsertBoard) {
      state.upsertBoard = upsertBoard
    },
    [DELETE_BOARD](state, deleteBoard) {
      state.deleteBoard = deleteBoard
    },
    [FETCH_FAQ](state, fetchFaq) {
      state.fetchFaq = fetchFaq
    },
    [UPSERT_FAQ](state, upsertFaq) {
      state.upsertFaq = upsertFaq
    },
    [DELETE_FAQ](state, deleteFaq) {
      state.deleteFaq = deleteFaq
    },
    [FETCH_SET_NOTICE_POPUP](state, fetchSetNoticePopup) {
      state.fetchSetNoticePopup = fetchSetNoticePopup
    },
    [UPSERT_SET_NOTICE_POPUP](state, upsertSetNoticePopup) {
      state.upsertSetNoticePopup = upsertSetNoticePopup
    },
    [DELETE_SET_NOTICE_POPUP](state, deleteSetNoticePopup) {
      state.deleteSetNoticePopup = deleteSetNoticePopup
    },
    [FETCH_SET_NOTICE_ADMIN](state, fetchSetNoticeAdmin) {
      state.fetchSetNoticeAdmin = fetchSetNoticeAdmin
    },
    [UPSERT_SET_NOTICE_ADMIN](state, upsertSetNoticeAdmin) {
      state.upsertSetNoticeAdmin = upsertSetNoticeAdmin
    },
    [DELETE_SET_NOTICE_ADMIN](state, deleteSetNoticeAdmin) {
      state.deleteSetNoticeAdmin = deleteSetNoticeAdmin
    },
    [FETCH_SET_NOTICE_ADMIN_CNT](state, fetchSetNoticeAdminCnt) {
      state.fetchSetNoticeAdminCnt = fetchSetNoticeAdminCnt
    },
    [UPDATE_SET_NOTICE_ADMIN_READ_AT](state, updateSetNoticeAdminReadAt) {
      state.updateSetNoticeAdminReadAt = updateSetNoticeAdminReadAt
    },
    [FETCH_REQUEST_ALARMS](state, fetchRequestAlarms) {
      state.fetchRequestAlarms = fetchRequestAlarms
    },
    [FETCH_REQUEST_ALARMS_FINISH](state, fetchRequestAlarmsFinish) {
      state.fetchRequestAlarmsFinish = fetchRequestAlarmsFinish
    },
    [FETCH_REQUEST_ALARMS_CNT](state, fetchRequestAlarmsCnt) {
      state.fetchRequestAlarmsCnt = fetchRequestAlarmsCnt
    },
    [UPSERT_REQUEST_ALARMS](state, upsertRequestAlarms) {
      state.upsertRequestAlarms = upsertRequestAlarms
    },
    [UPSERT_DEPOSIT](state, upsertDeposit) {
      state.upsertDeposit = upsertDeposit
    },
    [UPSERT_EXCHANGE](state, upsertExchange) {
      state.upsertExchange = upsertExchange
    },
    [FETCH_DEPOSIT_SMPL](state, fetchDepositSmpl) {
      state.fetchDepositSmpl = fetchDepositSmpl
    },
    [UPDATE_USERS_CASH_POINT](state, updateUsersCashPoint) {
      state.updateUsersCashPoint = updateUsersCashPoint
    },
    [FETCH_REQUEST_ALARMS_SIGNUP](state, fetchRequestAlarmsSignup) {
      state.fetchRequestAlarmsSignup = fetchRequestAlarmsSignup
    },
    [FETCH_REQUEST_ALARMS_SIGNUP_FINISH](state, fetchRequestAlarmsSignupFinish) {
      state.fetchRequestAlarmsSignupFinish = fetchRequestAlarmsSignupFinish
    },
    [FETCH_REQUEST_ALARMS_SIGNUP_CNT](state, fetchRequestAlarmsSignupCnt) {
      state.fetchRequestAlarmsSignupCnt = fetchRequestAlarmsSignupCnt
    },
    [FETCH_REQUEST_ALARMS_BET](state, fetchRequestAlarmsBet) {
      state.fetchRequestAlarmsBet = fetchRequestAlarmsBet
    },
    [FETCH_REQUEST_ALARMS_BET_FINISH](state, fetchRequestAlarmsBetFinish) {
      state.fetchRequestAlarmsBetFinish = fetchRequestAlarmsBetFinish
    },
    [FETCH_REQUEST_ALARMS_BET_ALL](state, fetchRequestAlarmsBetAll) {
      state.fetchRequestAlarmsBetAll = fetchRequestAlarmsBetAll
    },
    [FETCH_REQUEST_ALARMS_BET_CNT](state, fetchRequestAlarmsBetCnt) {
      state.fetchRequestAlarmsBetCnt = fetchRequestAlarmsBetCnt
    },
    [FETCH_REQUEST_ALARMS_DEPOSIT](state, fetchRequestAlarmsDeposit) {
      state.fetchRequestAlarmsDeposit = fetchRequestAlarmsDeposit
    },
    [FETCH_REQUEST_ALARMS_DEPOSIT_FINISH](state, fetchRequestAlarmsDepositFinish) {
      state.fetchRequestAlarmsDepositFinish = fetchRequestAlarmsDepositFinish
    },
    [FETCH_REQUEST_ALARMS_DEPOSIT_CNT](state, fetchRequestAlarmsDepositCnt) {
      state.fetchRequestAlarmsDepositCnt = fetchRequestAlarmsDepositCnt
    },
    [FETCH_REQUEST_ALARMS_EXCHANGE](state, fetchRequestAlarmsExchange) {
      state.fetchRequestAlarmsExchange = fetchRequestAlarmsExchange
    },
    [FETCH_REQUEST_ALARMS_EXCHANGE_FINISH](state, fetchRequestAlarmsExchangeFinish) {
      state.fetchRequestAlarmsExchangeFinish = fetchRequestAlarmsExchangeFinish
    },
    [FETCH_REQUEST_ALARMS_EXCHANGE_CNT](state, fetchRequestAlarmsExchangeCnt) {
      state.fetchRequestAlarmsExchangeCnt = fetchRequestAlarmsExchangeCnt
    },
    [FETCH_REQUEST_ALARMS_BRANCH_CASHOUT](state, fetchRequestAlarmsBranchCashout) {
      state.fetchRequestAlarmsBranchCashout = fetchRequestAlarmsBranchCashout
    },
    [FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH](state, fetchRequestAlarmsBranchCashoutFinish) {
      state.fetchRequestAlarmsBranchCashoutFinish = fetchRequestAlarmsBranchCashoutFinish
    },
    [FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT](state, fetchRequestAlarmsBranchCashoutCnt) {
      state.fetchRequestAlarmsBranchCashoutCnt = fetchRequestAlarmsBranchCashoutCnt
    },
    [UPDATE_USER_SIGNUP_CONFIRM](state, updateUserSignupConfirm) {
      state.updateUserSignupConfirm = updateUserSignupConfirm
    },
    [UPDATE_USER_SIGNUP_REJECT](state, updateUserSignupReject) {
      state.updateUserSignupReject = updateUserSignupReject
    },
    [UPDATE_MANY_DEPOSIT_CONFIRM](state, updateManyDepositConfirm) {
      state.updateManyDepositConfirm = updateManyDepositConfirm
    },
    [UPDATE_MANY_DEPOSIT_REJECT](state, updateManyDepositReject) {
      state.updateManyDepositReject = updateManyDepositReject
    },
    [UPDATE_MANY_DEPOSIT_CANCEL](state, updateManyDepositCancel) {
      state.updateManyDepositCancel = updateManyDepositCancel
    },
    [UPDATE_MANY_EXCHANGE_CONFIRM](state, updateManyExchangeConfirm) {
      state.updateManyExchangeConfirm = updateManyExchangeConfirm
    },
    [UPDATE_MANY_EXCHANGE_REJECT](state, updateManyExchangeReject) {
      state.updateManyExchangeReject = updateManyExchangeReject
    },
    [UPDATE_MANY_EXCHANGE_CANCEL](state, updateManyExchangeCancel) {
      state.updateManyExchangeCancel = updateManyExchangeCancel
    },
    [UPDATE_MANY_BRANCH_CASHOUT_CONFIRM](state, updateManyBranchCashoutConfirm) {
      state.updateManyBranchCashoutConfirm = updateManyBranchCashoutConfirm
    },
    [UPDATE_MANY_BRANCH_CASHOUT_REJECT](state, updateManyBranchCashoutReject) {
      state.updateManyBranchCashoutReject = updateManyBranchCashoutReject
    },
    [UPDATE_MANY_BRANCH_CASHOUT_CANCEL](state, updateManyBranchCashoutCancel) {
      state.updateManyBranchCashoutCancel = updateManyBranchCashoutCancel
    },
    updateParam(state, events) {
      // console.log('t >> ', events.t) // target
      // console.log('e >> ', events.e) // event
      // console.log('v >> ', events.v) // value
      state[events.t][0][events.e] = events.v
      // console.log('storeData', state.fetchSetBasic[0])
    },
    socketFetchRequestAlarms(state, data) {
      state.fetchRequestAlarms = data
    },
    socketFetchRequestAlarmsFinish(state, data) {
      state.fetchRequestAlarmsFinish = data
    },
    socketFetchRequestAlarmsBet(state, data) {
      state.fetchRequestAlarmsBet = data
    },
    socketFetchRequestAlarmsBetFinish(state, data) {
      state.fetchRequestAlarmsBetFinish = data
    },
    socketFetchRequestAlarmsDeposit(state, data) {
      state.fetchRequestAlarmsDeposit = data
    },
    socketFetchRequestAlarmsDepositFinish(state, data) {
      state.fetchRequestAlarmsDepositFinish = data
    },
    socketFetchRequestAlarmsExchange(state, data) {
      state.fetchRequestAlarmsExchange = data
    },
    socketFetchRequestAlarmsExchangeFinish(state, data) {
      state.fetchRequestAlarmsExchangeFinish = data
    },
    socketFetchRequestAlarmsBranchCashout(state, data) {
      state.fetchRequestAlarmsBranchCashout = data
    },
    socketFetchRequestAlarmsBranchCashoutFinish(state, data) {
      state.fetchRequestAlarmsBranchCashoutFinish = data
    },
    socketFetchRequestAlarmsSignup(state, data) {
      state.fetchRequestAlarmsSignup = data
    },
    socketFetchRequestAlarmsSignupFinish(state, data) {
      state.fetchRequestAlarmsSignupFinish = data
    },
  },
  actions: {
    socketFetchRequestAlarms({ commit }, data) {
      commit('socketFetchRequestAlarms', data)
    },
    socketFetchRequestAlarmsFinish({ commit }, data) {
      commit('socketFetchRequestAlarmsFinish', data)
    },
    socketFetchRequestAlarmsBet({ commit }, data) {
      commit('socketFetchRequestAlarmsBet', data)
    },
    socketFetchRequestAlarmsBetFinish({ commit }, data) {
      commit('socketFetchRequestAlarmsBetFinish', data)
    },
    socketFetchRequestAlarmsDeposit({ commit }, data) {
      commit('socketFetchRequestAlarmsDeposit', data)
    },
    socketFetchRequestAlarmsDepositFinish({ commit }, data) {
      commit('socketFetchRequestAlarmsDepositFinish', data)
    },
    socketFetchRequestAlarmsExchange({ commit }, data) {
      commit('socketFetchRequestAlarmsExchange', data)
    },
    socketFetchRequestAlarmsExchangeFinish({ commit }, data) {
      commit('socketFetchRequestAlarmsExchangeFinish', data)
    },
    socketFetchRequestAlarmsBranchCashout({ commit }, data) {
      commit('socketFetchRequestAlarmsBranchCashout', data)
    },
    socketFetchRequestAlarmsBranchCashoutFinish({ commit }, data) {
      commit('socketFetchRequestAlarmsBranchCashoutFinish', data)
    },
    socketFetchRequestAlarmsSignup({ commit }, data) {
      commit('socketFetchRequestAlarmsSignup', data)
    },
    socketFetchRequestAlarmsSignupFinish({ commit }, data) {
      commit('socketFetchRequestAlarmsSignupFinish', data)
    },
    async [FETCH_BOARD]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchBoard($site: String) {
            fetchBoard(site: $site) {
              idx
              site
              title
              content
              enrollId
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BOARD, data.fetchBoard)
      console.log('FETCH_BOARD : ', data.fetchBoard)
    },
    async [UPSERT_BOARD]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertBoard($site: String, $title: String, $content: String, $enrollId: String, $userIp: String){
            upsertBoard(site: $site, title: $title, content: $content, enrollId: $enrollId, userIp: $userIp) {
              site
              title
              content
              enrollId
            }
          }
        `,
        variables: {
          site: payload.site,
          title: payload.title,
          content: payload.content,
          enrollId: payload.enrollId,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_BOARD, data.upsertBoard)
      console.log('UPSERT_BOARD : ', data.upsertBoard)
    },
    async [DELETE_BOARD]({ commit, state }, payload) {
      // console.log('deleteBoard payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteBoard($site: String, $title: String, $enrollId: String, $userIp: String){
            deleteBoard(site: $site, title: $title, enrollId: $enrollId, userIp: $userIp) {
              site
              title
            }
          }
        `,
        variables: {
          site: payload.site,
          title: payload.title,
          enrollId: payload.enrollId,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_BOARD, data.deleteBoard)
      console.log('DELETE_BOARD : ', data.deleteBoard)
    },

    async [FETCH_FAQ]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetNoticeFaq($site: String) {
            fetchSetNoticeFaq(site: $site) {
              idx
              site
              category
              title
              content
              enrollId
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_FAQ, data.fetchSetNoticeFaq)
      console.log('FETCH_FAQ : ', data.fetchSetNoticeFaq)
    },
    async [UPSERT_FAQ]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertFaq($site: String, $category: String, $title: String, $content: String, $enrollId: String, $userIp: String){
            upsertSetNoticeFaq(site: $site, category: $category, title: $title, content: $content, enrollId: $enrollId, userIp: $userIp){
              site
              category
              title
              content
              enrollId
            }
          }
        `,
        variables: {
          site: payload.site,
          category: payload.category,
          title: payload.title,
          content: payload.content,
          enrollId: payload.enrollId,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_FAQ, data.upsertFaq)
      console.log('UPSERT_FAQ : ', data.upsertFaq)
    },
    async [DELETE_FAQ]({ commit, state }, payload) {
      // console.log('deleteBoard payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteFaq($site: String, $category: String, $title: String, $enrollId: String, $userIp: String) {
            deleteSetNoticeFaq(site: $site, category: $category, title: $title, enrollId: $enrollId, userIp: $userIp) {
              site
              category
              title
            }
          }
        `,
        variables: {
          site: payload.site,
          category: payload.category,
          title: payload.title,
          enrollId: payload.enrollId,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_FAQ, data.deleteBoard)
      console.log('DELETE_FAQ : ', data.deleteBoard)
    },
    async [FETCH_SET_NOTICE_POPUP]({ commit }, payload) {
      // console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetNoticePopup($site: String) {
            fetchSetNoticePopup(site: $site) {
              idx
              site
              title
              activeFrom
              activeTo
              active
              content
              enrollId
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_NOTICE_POPUP, data.fetchSetNoticePopup)
      console.log('FETCH_SET_NOTICE_POPUP : ', data.fetchSetNoticePopup)
    },

    async [UPSERT_SET_NOTICE_POPUP]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertSetNoticePopup($site: String, $title: String, $content: String, $activeFrom: String, $activeTo: String, $active: String, $enrollId: String, $userIp: String){
            upsertSetNoticePopup(site: $site, title: $title, content: $content, activeFrom: $activeFrom, activeTo: $activeTo, active: $active, enrollId: $enrollId, userIp: $userIp) {
              site
              title
              content
              activeFrom
              activeTo
              active
              enrollId
            }
          }
        `,
        variables: {
          site: payload.site,
          title: payload.title,
          content: payload.content,
          activeFrom: payload.activeFrom,
          activeTo: payload.activeTo,
          active: payload.active,
          enrollId: payload.enrollId,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_SET_NOTICE_POPUP, data.upsertSetNoticePopup)
      console.log('UPSERT_SET_NOTICE_POPUP : ', data.upsertSetNoticePopup)
    },
    async [DELETE_SET_NOTICE_POPUP]({ commit, state }, payload) {
      console.log('delete payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSetNoticePopup($site: String, $title: String, $enrollId: String, $userIp: String){
            deleteSetNoticePopup(site: $site, title: $title, enrollId: $enrollId, userIp: $userIp) {
              site
              title
            }
          }
        `,
        variables: {
          site: payload.site,
          title: payload.title,
          enrollId: payload.enrollId,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_SET_NOTICE_POPUP, data.deleteSetNoticePopup)
      console.log('DELETE_SET_NOTICE_POPUP : ', data.deleteSetNoticePopup)
    },
    async [FETCH_SET_NOTICE_ADMIN]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetNoticeAdmin($site: String) {
            fetchSetNoticeAdmin(site: $site) {
              idx
              site
              category
              title
              content
              enrollId
              readAt
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_NOTICE_ADMIN, data.fetchSetNoticeAdmin)
      console.log('FETCH_SET_NOTICE_ADMIN : ', data.fetchSetNoticeAdmin)
    },

    async [UPSERT_SET_NOTICE_ADMIN]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertSetNoticeAdmin($site: String, $category: String, $title: String, $content: String, $enrollId: String){
            upsertSetNoticeAdmin(site: $site, category: $category, title: $title, content: $content, enrollId: $enrollId) {
              site
              category
              title
              content
              enrollId
            }
          }
        `,
        variables: {
          site: payload.site,
          category: payload.category,
          title: payload.title,
          content: payload.content,
          enrollId: payload.enrollId,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_SET_NOTICE_ADMIN, data.upsertSetNoticeAdmin)
      console.log('UPSERT_SET_NOTICE_ADMIN : ', data.upsertSetNoticeAdmin)
    },
    async [DELETE_SET_NOTICE_ADMIN]({ commit, state }, payload) {
      console.log('delete payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSetNoticeAdmin($site: String, $category: String, $title: String){
            deleteSetNoticeAdmin(site: $site, category: $category, title: $title) {
              site
              category
              title
            }
          }
        `,
        variables: {
          site: payload.site,
          category: payload.category,
          title: payload.title,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_SET_NOTICE_ADMIN, data.deleteSetNoticeAdmin)
      console.log('DELETE_SET_NOTICE_ADMIN : ', data.deleteSetNoticeAdmin)
    },

    async [FETCH_SET_NOTICE_ADMIN_CNT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetNoticeAdminCnt($site: [String!]) {
            fetchSetNoticeAdminCnt(site: $site)
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_NOTICE_ADMIN_CNT, data.fetchSetNoticeAdminCnt)
      console.log('FETCH_SET_NOTICE_ADMIN_CNT : ', data.fetchSetNoticeAdminCnt)
    },

    async [UPDATE_SET_NOTICE_ADMIN_READ_AT]({ commit, state }, payload) {
      console.log('update readAt payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetNoticeAdminReadAt($site: String, $title: String, $readAt: String){
            updateSetNoticeAdminReadAt(site: $site, title: $title, readAt: $readAt) {
              site
              title
              readAt
            }
          }
        `,
        variables: {
          site: payload.site,
          title: payload.title,
          readAt: payload.readAt,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_NOTICE_ADMIN_READ_AT, data.updateSetNoticeAdminReadAt)
      console.log('UPDATE_SET_NOTICE_ADMIN_READ_AT : ', data.updateSetNoticeAdminReadAt)
    },

    async [FETCH_REQUEST_ALARMS]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarms($site: [String!], $result: String, $read: String, $uid: String) {
            fetchRequestAlarms(site: $site, result: $result, read: $read, uid: $uid) {
              idx
              site
              category
              title
              content
              requestId
              read
              readId
              readAt
              confirm
              confirmId
              confirmAt
              result
              uid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          result: payload.result,
          read: payload.read,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS, data.fetchRequestAlarms)
      console.log('FETCH_REQUEST_ALARMS : ', data.fetchRequestAlarms)
    },
    async [FETCH_REQUEST_ALARMS_FINISH]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarms($site: [String!], $result: String) {
            fetchRequestAlarms(site: $site, result: $result) {
              idx
              site
              category
              title
              content
              requestId
              read
              readId
              readAt
              confirm
              confirmId
              confirmAt
              result
              uid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          result: payload.result,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_FINISH, data.fetchRequestAlarms)
      console.log('FETCH_REQUEST_ALARMS_FINISH : ', data.fetchRequestAlarms)
    },

    async [FETCH_REQUEST_ALARMS_CNT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsCnt($site: [String!], $result: String) {
            fetchRequestAlarmsCnt(site: $site, result: $result)
          }
        `,
        variables: {
          site: payload.site,
          result: payload.result,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_CNT, data.fetchRequestAlarmsCnt)
      console.log('FETCH_REQUEST_ALARMS_CNT : ', data.fetchRequestAlarmsCnt)
    },

    async [UPSERT_REQUEST_ALARMS]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertRequestAlarms($idx: Float, $site: String, $category: String, $title: String, $content: String, $requestId: String, $read: String, $readId: String, $readAt: String, $confirm: String, $confirmId: String, $confirmAt: String, $result: String, $uid: String){
            upsertRequestAlarms(idx: $idx, site: $site, category: $category, title: $title, content: $content, requestId: $requestId, read: $read, readId: $readId, readAt: $readAt, confirm: $confirm, confirmId: $confirmId, confirmAt: $confirmAt, result: $result, uid: $uid) {
              idx
              site
              category
              title
              content
              requestId
              read
              readId
              readAt
              confirm
              confirmId
              confirmAt
              result
              uid
            }
          }
        `,
        variables: {
          idx: payload.idx,
          site: payload.site,
          category: payload.category,
          title: payload.title,
          content: payload.content,
          requestId: payload.requestId,
          read: payload.read,
          readId: payload.readId,
          readAt: payload.readAt,
          confirm: payload.confirm,
          confirmId: payload.confirmId,
          confirmAt: payload.confirmAt,
          result: payload.result,
          uid: payload.uid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_REQUEST_ALARMS, data.upsertRequestAlarms)
      console.log('UPSERT_REQUEST_ALARMS : ', data.upsertRequestAlarms)
    },

    async [FETCH_REQUEST_ALARMS_SIGNUP]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsSignup($site: [String!], $confirmed: String) {
            fetchRequestAlarmsSignup(site: $site, confirmed: $confirmed) {
              idx
              site
              category
              signupId
              recommendNickname
              requestId
              requestNickname
              requestDate
              requestIp
              requestName
              mobile
              accountBank
              accountNumber
              confirmed
              confirmId
              confirmAt
              confirmIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_SIGNUP, data.fetchRequestAlarmsSignup)
      console.log('FETCH_REQUEST_ALARMS_SIGNUP : ', data.fetchRequestAlarmsSignup)
    },

    async [FETCH_REQUEST_ALARMS_SIGNUP_FINISH]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsSignupFinish($site: [String!], $confirmed: String, $periodFrom: String, $periodTo: String) {
            fetchRequestAlarmsSignupFinish(site: $site, confirmed: $confirmed, periodFrom: $periodFrom, periodTo: $periodTo) {
              idx
              site
              category
              signupId
              recommendNickname
              requestId
              requestNickname
              requestDate
              requestIp
              requestName
              mobile
              accountBank
              accountNumber
              confirmed
              confirmId
              confirmAt
              confirmIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_SIGNUP_FINISH, data.fetchRequestAlarmsSignupFinish)
      console.log('FETCH_REQUEST_ALARMS_SIGNUP_FINISH : ', data.fetchRequestAlarmsSignupFinish)
    },

    async [FETCH_REQUEST_ALARMS_SIGNUP_CNT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsSignupCnt($site: [String!], $confirmed: String) {
            fetchRequestAlarmsSignupCnt(site: $site, confirmed: $confirmed)
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_SIGNUP_CNT, data.fetchRequestAlarmsSignupCnt)
      console.log('FETCH_REQUEST_ALARMS_SIGNUP_CNT : ', data.fetchRequestAlarmsSignupCnt)
    },

    async [FETCH_REQUEST_ALARMS_BET]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsBet($site: [String!], $confirmed: String) {
            fetchRequestAlarmsBet(site: $site, confirmed: $confirmed) {
              idx
              site
              sports
              category
              title
              groupId
              gameDate
              betDate
              betCash
              betResult
              fold
              totalOdds
              totalHitCash
              requestId
              requestNickname
              requestIp
              confirmed
              confirmId
              confirmAt
              confirmIp
              uid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_BET, data.fetchRequestAlarmsBet)
      console.log('FETCH_REQUEST_ALARMS_BET : ', data.fetchRequestAlarmsBet)
    },
    async [FETCH_REQUEST_ALARMS_BET_FINISH]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsBetFinish($site: [String!], $requestId: String, $confirmed: String, $periodFrom: String, $periodTo: String, $betResult: String, $confirmAtFrom: String, $confirmAtTo: String) {
            fetchRequestAlarmsBetFinish(site: $site, requestId: $requestId, confirmed: $confirmed, periodFrom: $periodFrom, periodTo: $periodTo, betResult: $betResult, confirmAtFrom: $confirmAtFrom, confirmAtTo: $confirmAtTo) {
              idx
              site
              sports
              category
              title
              groupId
              gameDate
              betDate
              betCash
              betResult
              fold
              totalOdds
              totalHitCash
              requestId
              requestNickname
              requestIp
              confirmed
              confirmId
              confirmAt
              confirmIp
              uid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          requestId: payload.requestId,
          confirmed: payload.confirmed,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          betResult: payload.betResult,
          confirmAtFrom: payload.confirmAtFrom,
          confirmAtTo: payload.confirmAtTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_BET_FINISH, data.fetchRequestAlarmsBetFinish)
      console.log('FETCH_REQUEST_ALARMS_BET_FINISH : ', data.fetchRequestAlarmsBetFinish)
    },

    async [FETCH_REQUEST_ALARMS_BET_ALL]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsBetAll($site: [String!], $confirmed: String, $periodFrom: String, $periodTo: String) {
            fetchRequestAlarmsBetAll(site: $site, confirmed: $confirmed, periodFrom: $periodFrom, periodTo: $periodTo) {
              idx
              site
              sports
              category
              title
              groupId
              gameDate
              betDate
              betCash
              betResult
              fold
              totalOdds
              totalHitCash
              requestId
              requestNickname
              requestIp
              confirmed
              confirmId
              confirmAt
              confirmIp
              uid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_BET_ALL, data.fetchRequestAlarmsBetAll)
      console.log('FETCH_REQUEST_ALARMS_BET_ALL : ', data.fetchRequestAlarmsBetAll)
    },

    async [FETCH_REQUEST_ALARMS_BET_CNT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsBetCnt($site: [String!], $confirmed: String) {
            fetchRequestAlarmsBetCnt(site: $site, confirmed: $confirmed)
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_BET_CNT, data.fetchRequestAlarmsBetCnt)
      console.log('FETCH_REQUEST_ALARMS_BET_CNT : ', data.fetchRequestAlarmsBetCnt)
    },

    async [FETCH_REQUEST_ALARMS_DEPOSIT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsDeposit($site: [String!], $confirmed: String) {
            fetchRequestAlarmsDeposit(site: $site, confirmed: $confirmed) {
              idx,
              site,
              category,
              paymentId,
              requestId,
              recommendNickname,
              requestNickname,
              requestDate,
              requestCash,
              requestType,
              requestRank,
              requestIp,
              requestName,
              accountBank,
              accountNumber,
              confirmed,
              confirmId,
              confirmAt,
              confirmIp,
              uid,
              createdAt,
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_DEPOSIT, data.fetchRequestAlarmsDeposit)
      console.log('FETCH_REQUEST_ALARMS_DEPOSIT : ', data.fetchRequestAlarmsDeposit)
    },
    async [FETCH_REQUEST_ALARMS_DEPOSIT_FINISH]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsDepositFinish($site: [String!], $confirmed: String, $periodFrom: String, $periodTo: String, $requestId: String) {
            fetchRequestAlarmsDepositFinish(site: $site, confirmed: $confirmed, periodFrom: $periodFrom, periodTo: $periodTo, requestId: $requestId) {
              idx,
              site,
              category,
              paymentId,
              requestId,
              recommendNickname,
              requestNickname,
              requestDate,
              requestCash,
              requestPoint,
              requestPointContent,
              requestType,
              requestRank,
              requestIp,
              requestName,
              accountBank,
              accountNumber,
              confirmed,
              confirmId,
              confirmAt,
              confirmIp,
              uid,
              createdAt,
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          requestId: payload.requestId,
          confirmed: payload.confirmed,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_DEPOSIT_FINISH, data.fetchRequestAlarmsDepositFinish)
      console.log('FETCH_REQUEST_ALARMS_DEPOSIT_FINISH : ', data.fetchRequestAlarmsDepositFinish)
    },
    async [FETCH_REQUEST_ALARMS_DEPOSIT_CNT]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsDepositCnt($site: [String!], $confirmed: String) {
            fetchRequestAlarmsDepositCnt(site: $site, confirmed: $confirmed)
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_DEPOSIT_CNT, data.fetchRequestAlarmsDepositCnt)
      console.log('FETCH_REQUEST_ALARMS_DEPOSIT_CNT : ', data.fetchRequestAlarmsDepositCnt)
    },
    async [FETCH_REQUEST_ALARMS_EXCHANGE]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsExchange($site: [String!], $confirmed: String) {
            fetchRequestAlarmsExchange(site: $site, confirmed: $confirmed) {
              idx,
              site,
              category,
              paymentId,
              requestId,
              recommendNickname,
              requestNickname,
              requestDate,
              requestCash,
              requestType,
              requestRank,
              requestIp,
              requestName,
              accountBank,
              accountNumber,
              confirmed,
              confirmId,
              confirmAt,
              confirmIp,
              uid,
              createdAt,
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_EXCHANGE, data.fetchRequestAlarmsExchange)
      console.log('FETCH_REQUEST_ALARMS_EXCHANGE : ', data.fetchRequestAlarmsExchange)
    },
    async [FETCH_REQUEST_ALARMS_EXCHANGE_FINISH]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsExchangeFinish($site: [String!], $confirmed: String, $periodFrom: String, $periodTo: String, $requestId: String) {
            fetchRequestAlarmsExchangeFinish(site: $site, confirmed: $confirmed, periodFrom: $periodFrom, periodTo: $periodTo, requestId: $requestId) {
              idx,
              site,
              category,
              paymentId,
              requestId,
              recommendNickname,
              requestNickname,
              requestDate,
              requestCash,
              requestType,
              requestRank,
              requestIp,
              requestName,
              accountBank,
              accountNumber,
              confirmed,
              confirmId,
              confirmAt,
              confirmIp,
              uid,
              createdAt,
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          requestId: payload.requestId,
          confirmed: payload.confirmed,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_EXCHANGE_FINISH, data.fetchRequestAlarmsExchangeFinish)
      console.log('FETCH_REQUEST_ALARMS_EXCHANGE_FINISH : ', data.fetchRequestAlarmsExchangeFinish)
    },
    async [FETCH_REQUEST_ALARMS_EXCHANGE_CNT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsExchangeCnt($site: [String!], $confirmed: String) {
            fetchRequestAlarmsExchangeCnt(site: $site, confirmed: $confirmed)
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_EXCHANGE_CNT, data.fetchRequestAlarmsExchangeCnt)
      console.log('FETCH_REQUEST_ALARMS_EXCHANGE_CNT : ', data.fetchRequestAlarmsExchangeCnt)
    },

    async [FETCH_REQUEST_ALARMS_BRANCH_CASHOUT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsBranchCashout($site: [String!], $confirmed: String) {
            fetchRequestAlarmsBranchCashout(site: $site, confirmed: $confirmed) {
              idx,
              site,
              category,
              paymentId,
              requestId,
              recommendNickname,
              requestNickname,
              requestDate,
              requestCash,
              requestType,
              requestRank,
              requestIp,
              requestName,
              requestMemo,
              accountBank,
              accountNumber,
              confirmed,
              confirmId,
              confirmAt,
              confirmIp,
              uid,
              createdAt,
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_BRANCH_CASHOUT, data.fetchRequestAlarmsBranchCashout)
      console.log('FETCH_REQUEST_ALARMS_BRANCH_CASHOUT : ', data.fetchRequestAlarmsBranchCashout)
    },

    async [FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsBranchCashoutFinish($site: [String!], $confirmed: String, $periodFrom: String, $periodTo: String, $requestId: String) {
            fetchRequestAlarmsBranchCashoutFinish(site: $site, confirmed: $confirmed, periodFrom: $periodFrom, periodTo: $periodTo, requestId: $requestId) {
              idx,
              site,
              category,
              paymentId,
              requestId,
              recommendNickname,
              requestNickname,
              requestDate,
              requestCash,
              requestType,
              requestRank,
              requestIp,
              requestName,
              requestMemo,
              accountBank,
              accountNumber,
              confirmed,
              confirmId,
              confirmAt,
              confirmIp,
              uid,
              createdAt,
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          requestId: payload.requestId,
          confirmed: payload.confirmed,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH, data.fetchRequestAlarmsBranchCashoutFinish)
      console.log('FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH : ', data.fetchRequestAlarmsBranchCashoutFinish)
    },

    async [FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchRequestAlarmsBranchCashoutCnt($site: [String!], $confirmed: String) {
            fetchRequestAlarmsBranchCashoutCnt(site: $site, confirmed: $confirmed)
          }
        `,
        variables: {
          site: payload.site,
          confirmed: payload.confirmed,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT, data.fetchRequestAlarmsBranchCashoutCnt)
      console.log('FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT : ', data.fetchRequestAlarmsBranchCashoutCnt)
    },

    async [UPDATE_USER_SIGNUP_CONFIRM]({ commit }, payload) {
      const inputData = {
        site: payload.rowData.site,
        signupId: payload.rowData.signupId,
        requestId: payload.rowData.requestId,
        confirmId: payload.rowData.confirmId,
        confirmIp: payload.rowData.confirmIp,
      }
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUserSignupConfirm($requestAlarmsSignupInputArgs: RequestAlarmsSignupInputArgs!, $ip: String!, $userid: String!){
            updateUserSignupConfirm(requestAlarmsSignupInputArgs: $requestAlarmsSignupInputArgs, ip: $ip, userid: $userid) {
              signupId
            }
          }
        `,
        variables: {
          requestAlarmsSignupInputArgs: { site: inputData.site, signupId: inputData.signupId, requestId: inputData.requestId, confirmId: inputData.confirmId, confirmIp: inputData.confirmIp },
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_USER_SIGNUP_CONFIRM, data.updateUserSignupConfirm)
      console.log('UPDATE_USER_SIGNUP_CONFIRM : ', data.updateUserSignupConfirm)
    },

    async [UPDATE_USER_SIGNUP_REJECT]({ commit }, payload) {
      const inputData = {
        site: payload.rowData.site,
        signupId: payload.rowData.signupId,
        requestId: payload.rowData.requestId,
      }

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUserSignupReject($requestAlarmsSignupInputArgs: RequestAlarmsSignupInputArgs!, $ip: String!, $userid: String!){
          updateUserSignupReject(requestAlarmsSignupInputArgs: $requestAlarmsSignupInputArgs, ip: $ip, userid: $userid) {
            signupId
          }
        }
        `,
        variables: {
          requestAlarmsSignupInputArgs: { site: inputData.site, signupId: inputData.signupId, requestId: inputData.requestId },
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_USER_SIGNUP_REJECT, data.updateUserSignupReject)
      console.log('UPDATE_USER_SIGNUP_REJECT : ', data.updateUserSignupReject)
    },

    async [UPDATE_MANY_DEPOSIT_CONFIRM]({ commit }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyDepositConfirm($requestAlarmsDepositInputArgs: [RequestAlarmsDepositInputArgs!]!, $ip: String!, $userid: String!){
            updateManyDepositConfirm(requestAlarmsDepositInputArgs: $requestAlarmsDepositInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsDepositInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_DEPOSIT_CONFIRM, data.updateManyDepositConfirm)
      console.log('UPDATE_MANY_DEPOSIT_CONFIRM : ', data.updateManyDepositConfirm)
    },
    async [UPDATE_MANY_DEPOSIT_REJECT]({ commit }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyDepositReject($requestAlarmsDepositInputArgs: [RequestAlarmsDepositInputArgs!]!, $ip: String!, $userid: String!){
            updateManyDepositReject(requestAlarmsDepositInputArgs: $requestAlarmsDepositInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsDepositInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_DEPOSIT_REJECT, data.updateManyDepositReject)
      console.log('UPDATE_MANY_DEPOSIT_REJECT : ', data.updateManyDepositReject)
    },
    async [UPDATE_MANY_DEPOSIT_CANCEL]({ commit }, payload) {
      console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyDepositCancel($requestAlarmsDepositInputArgs: [RequestAlarmsDepositInputArgs!]!, $ip: String!, $userid: String!){
            updateManyDepositCancel(requestAlarmsDepositInputArgs: $requestAlarmsDepositInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsDepositInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_DEPOSIT_CANCEL, data.updateManyDepositCancel)
      console.log('UPDATE_MANY_DEPOSIT_CANCEL : ', data.updateManyDepositCancel)
    },
    async [UPDATE_MANY_EXCHANGE_CONFIRM]({ commit }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyExchangeConfirm($requestAlarmsExchangeInputArgs: [RequestAlarmsExchangeInputArgs!]!, $ip: String!, $userid: String!){
            updateManyExchangeConfirm(requestAlarmsExchangeInputArgs: $requestAlarmsExchangeInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsExchangeInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_EXCHANGE_CONFIRM, data.updateManyExchangeConfirm)
      console.log('UPDATE_MANY_EXCHANGE_CONFIRM : ', data.updateManyExchangeConfirm)
    },
    async [UPDATE_MANY_EXCHANGE_REJECT]({ commit }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyExchangeReject($requestAlarmsExchangeInputArgs: [RequestAlarmsExchangeInputArgs!]!, $ip: String!, $userid: String!){
            updateManyExchangeReject(requestAlarmsExchangeInputArgs: $requestAlarmsExchangeInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsExchangeInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_EXCHANGE_REJECT, data.updateManyExchangeReject)
      console.log('UPDATE_MANY_EXCHANGE_REJECT : ', data.updateManyExchangeReject)
    },
    async [UPDATE_MANY_EXCHANGE_CANCEL]({ commit }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyExchangeCancel($requestAlarmsExchangeInputArgs: [RequestAlarmsExchangeInputArgs!]!, $ip: String!, $userid: String!){
            updateManyExchangeCancel(requestAlarmsExchangeInputArgs: $requestAlarmsExchangeInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsExchangeInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_EXCHANGE_CANCEL, data.updateManyExchangeCancel)
      console.log('UPDATE_MANY_EXCHANGE_CANCEL : ', data.updateManyExchangeCancel)
    },

    async [UPDATE_MANY_BRANCH_CASHOUT_CONFIRM]({ commit }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyBranchCashoutConfirm($requestAlarmsBranchCashoutInputArgs: [RequestAlarmsBranchCashoutInputArgs!]!, $ip: String!, $userid: String!){
            updateManyBranchCashoutConfirm(requestAlarmsBranchCashoutInputArgs: $requestAlarmsBranchCashoutInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsBranchCashoutInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_BRANCH_CASHOUT_CONFIRM, data.updateManyBranchCashoutConfirm)
      console.log('UPDATE_MANY_BRANCH_CASHOUT_CONFIRM : ', data.updateManyBranchCashoutConfirm)
    },

    async [UPDATE_MANY_BRANCH_CASHOUT_REJECT]({ commit }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyBranchCashoutReject($requestAlarmsBranchCashoutInputArgs: [RequestAlarmsBranchCashoutInputArgs!]!, $ip: String!, $userid: String!){
            updateManyBranchCashoutReject(requestAlarmsBranchCashoutInputArgs: $requestAlarmsBranchCashoutInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsBranchCashoutInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_BRANCH_CASHOUT_REJECT, data.updateManyBranchCashoutReject)
      console.log('UPDATE_MANY_BRANCH_CASHOUT_REJECT : ', data.updateManyBranchCashoutReject)
    },

    async [UPDATE_MANY_BRANCH_CASHOUT_CANCEL]({ commit }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManyBranchCashoutCancel($requestAlarmsBranchCashoutInputArgs: [RequestAlarmsBranchCashoutInputArgs!]!, $ip: String!, $userid: String!){
            updateManyBranchCashoutCancel(requestAlarmsBranchCashoutInputArgs: $requestAlarmsBranchCashoutInputArgs, ip: $ip, userid: $userid) {
              paymentId
            }
          }
        `,
        variables: {
          requestAlarmsBranchCashoutInputArgs: payload.paymentId.map(paymentId => ({
            paymentId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          })),
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MANY_BRANCH_CASHOUT_CANCEL, data.updateManyBranchCashoutCancel)
      console.log('UPDATE_MANY_BRANCH_CASHOUT_CANCEL : ', data.updateManyBranchCashoutCancel)
    },

    async [FETCH_DEPOSIT_SMPL]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchDepositSmpl($site: String, $userid: String, $type: String) {
            fetchDepositSmpl(site: $site, userid: $userid, type: $type) {
              idx
              site
              userid
              deposit
              type
              userBank
              userAccount
              adminBank
              adminAccount
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          type: payload.type,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_DEPOSIT_SMPL, data.fetchDepositSmpl)
      console.log('FETCH_DEPOSIT_SMPL : ', data.fetchDepositSmpl)
    },
    async [UPSERT_DEPOSIT]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertDeposit($idx: Float, $site: String, $userid: String, $deposit: Float, $type: String, $userBank: String, $userAccount: String, $adminBank: String, $adminAccount: String, $uid: String){
            upsertDeposit(idx: $idx, site: $site, userid: $userid, deposit: $deposit, type: $type, userBank: $userBank, userAccount: $userAccount, adminBank: $adminBank, adminAccount: $adminAccount, uid: $uid) {
              idx
              site
              userid
              deposit
              type
              userBank
              userAccount
              adminBank
              adminAccount
              uid
            }
          }
        `,
        variables: {
          uid: payload.uid,
          site: payload.site,
          userid: payload.userid,
          deposit: payload.deposit,
          type: payload.type,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_DEPOSIT, data.upsertDeposit)
      console.log('UPSERT_DEPOSIT : ', data.upsertDeposit)
    },
    async [UPSERT_EXCHANGE]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertExchange($idx: Float, $site: String, $userid: String, $exchange: Float, $type: String, $userBank: String, $userAccount: String, $adminBank: String, $adminAccount: String, $uid: String){
            upsertExchange(idx: $idx, site: $site, userid: $userid, exchange: $exchange, type: $type, userBank: $userBank, userAccount: $userAccount, adminBank: $adminBank, adminAccount: $adminAccount, uid: $uid) {
              idx
              site
              userid
              exchange
              type
              userBank
              userAccount
              adminBank
              adminAccount
              uid
            }
          }
        `,
        variables: {
          uid: payload.uid,
          site: payload.site,
          userid: payload.userid,
          exchange: payload.exchange,
          type: payload.type,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_EXCHANGE, data.upsertExchange)
      console.log('UPSERT_EXCHANGE : ', data.upsertExchange)
    },
    async [UPDATE_USERS_CASH_POINT]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUsersCashPoint($site: String, $userid: String, $cash: Float, $point: Float){
            updateUsersCashPoint(site: $site, userid: $userid, cash: $cash, point: $point) {
              site
              userid
              cash
              point
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          cash: payload.cash,
          point: payload.point,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_USERS_CASH_POINT, data.updateUsersCashPoint)
      console.log('UPDATE_USERS_CASH_POINT : ', data.updateUsersCashPoint)
    },
    // async [DELETE_SET_NOTICE_ALARM]({ commit, state }, payload) {
    //   console.log('delete payload : ', payload)
    //   const { data } = await apolloClient.mutate({
    //     mutation: gql`
    //       mutation deleteSetNoticeAlarm($idx: Float){
    //         deleteSetNoticeAlarm(idx: $idx) {
    //           idx
    //         }
    //       }
    //     `,
    //     variables: {
    //       idx: payload.idx,
    //     },
    //     fetchPolicy: 'no-cache',
    //   })
    //   commit(DELETE_SET_NOTICE_ALARM, data.deleteSetNoticeAlarm)
    //   console.log('DELETE_SET_NOTICE_ALARM : ', data.deleteSetNoticeAlarm)
    // },
  },
}

export default noticeStore
