/* eslint-disable */
// prettier-ignore
export default {
    pages: {
        key: 'title',
        data: [
            {title: 'Home', route: {name: 'home'}, icon: '', isBookmarked: false},
            {title: '관리자 공지사항', route: {name: 'notice-admin'}, icon: '', isBookmarked: false},
            {title: '기본 설정', route: {name: 'basic-settings'}, icon: '', isBookmarked: false},
            {title: '등급 관리', route: {name: 'rank-settings'}, icon: '', isBookmarked: false},
            {title: '이벤트 관리', route: {name: 'event-settings'}, icon: '', isBookmarked: false},
            {title: '계좌 관리', route: {name: 'account-settings'}, icon: '', isBookmarked: false},
            {title: 'IP 관리', route: {name: 'ip-settings'}, icon: '', isBookmarked: false},
            {title: '알림 관리', route: {name: 'page-layout-layout-blank'}, icon: '', isBookmarked: false},
            {title: '공지사항 관리', route: {name: 'notice-settings'}, icon: '', isBookmarked: true},
            {title: 'FAQ 관리', route: {name: 'faq-settings'}, icon: '', isBookmarked: false},
            {title: '팝업 관리', route: {name: 'popup-settings'}, icon: '', isBookmarked: false},
            {title: '게임 설정', route: {name: 'game-settings'}, icon: '', isBookmarked: false},
            {title: '배팅 리스트 (종합)', route: {name: 'betting-list'}, icon: '', isBookmarked: false},
            {title: '배팅 리스트 (경기별)', route: {name: 'betting-list-by-match'}, icon: '', isBookmarked: false},
            {title: '경기 관리 (발매)', route: {name: 'match-manage-betting'}, icon: '', isBookmarked: false},
            {title: '경기 관리 (결과)', route: {name: 'match-manage-result'}, icon: '', isBookmarked: false},
            {title: '적중 결과', route: {name: 'refund-hit-result'}, icon: '', isBookmarked: false},
            {title: '수동 경기 (등록)', route: {name: 'custom-match-addon'}, icon: '', isBookmarked: false},
            {title: '수동 경기 (결과)', route: {name: 'custom-match-result'}, icon: '', isBookmarked: false},
            {title: '게임 설정 (카지노)', route: {name: 'game-settings-casino'}, icon: '', isBookmarked: false},
            {title: '카지노 게임 내역', route: {name: 'casino-game-transaction'}, icon: '', isBookmarked: false},
            {title: '총판 관리', route: {name: 'branch-list'}, icon: '', isBookmarked: false},
            {title: '총판 등록관리', route: {name: 'branch-create'}, icon: '', isBookmarked: false},
            {title: '총판 일정산관리', route: {name: 'branch-calculate'}, icon: '', isBookmarked: false},
            {title: '총판 그룹별관리', route: {name: 'branch-calculate-group'}, icon: '', isBookmarked: false},
            {title: '총판 월별현황', route: {name: 'branch-monthly'}, icon: '', isBookmarked: false},
            {title: '회원 관리', route: {name: 'apps-users-list'}, icon: '', isBookmarked: false},
            {title: '실시간 접속 회원', route: {name: 'apps-users-list-now'}, icon: '', isBookmarked: false},
            {title: '회원 접속 로그', route: {name: 'users-connect-logs'}, icon: '', isBookmarked: false},
            {title: '신규 회원', route: {name: 'users-Signup-list'}, icon: '', isBookmarked: false},
            {title: '충환전 관리', route: {name: 'cash-in-out-list'}, icon: '', isBookmarked: false},
            {title: '충전 관리', route: {name: 'deposit-list'}, icon: '', isBookmarked: false},
            {title: '환전 관리', route: {name: 'exchange-list'}, icon: '', isBookmarked: false},
            {title: '총판 출금 관리', route: {name: 'branch-cashout-list'}, icon: '', isBookmarked: false},
            {title: '쪽지 발송', route: {name: 'messages-send-admin'}, icon: '', isBookmarked: false},
            {title: '쪽지 내역 ⇄ 회원(유저)', route: {name: 'messages-history-user'}, icon: '', isBookmarked: false},
            {title: '쪽지 내역 ⇄ 시스템 관리자', route: {name: 'messages-history-admin'}, icon: '', isBookmarked: false},
            {title: '쪽지 내역 ⇄ 비회원', route: {name: 'messages-guest'}, icon: '', isBookmarked: false},
        ],
    },
    files: {
        key: 'file_name',
        data: [],
    },
    contacts: {
        key: 'name',
        data: [
            // {
            //     img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
            //     name: 'Rena Brant',
            //     email: 'nephrod@preany.co.uk',
            //     time: '21/05/2019',
            // },
        ],
    },
}
/* eslint-enable */
