import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authStore from './auth'
import userStore from './users'
import branchStore from './branch'
import bookmarkStore from './bookmark'
import notification from './notification'
import logStore from './logs'
import settingsStore from './settings'
import noticeStore from './notice'
import messagesStore from './messages'
import footerStore from './footer'
import resultStore from './result'
import gameStore from './game'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    authStore,
    userStore,
    branchStore,
    bookmarkStore,
    verticalMenu,
    notification,
    logStore,
    settingsStore,
    noticeStore,
    messagesStore,
    footerStore,
    resultStore,
    gameStore,
  },
  plugins: [
    createPersistedState({
      paths: ['bookmarkStore'],
    }),
  ],
  strict: process.env.DEV,
})
