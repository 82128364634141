<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import notificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown'

import { CHECK_EXPIRED_JWT } from '@/store/auth/action'
import { FETCH_SET_ALARMS_ARRAY, FETCH_SET_SITE_ADMIN } from '@/store/settings/action'
import { createNamespacedHelpers } from 'vuex'
import io from 'socket.io-client'
import Vue from 'vue'
import $store from "@/store/auth"
import AWS from 'aws-sdk'
import Swal from "sweetalert2";
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import {UPDATE_SET_ALARMS} from "@/store/settings/mutation";

const authStore = createNamespacedHelpers('authStore')

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  created() {
    // if (store.getters['authStore/token'] && localStorage.getItem('userData')) {
    // 소켓 연결 (마운트 이전)
    if (store.getters['authStore/isAuthenticated'] && store.getters['authStore/isUser'] && store.getters['authStore/isFactor']) {
      this.connectToSocket()
      // this.socketListener()
    }
    watch(
      () => store.getters['authStore/isAuthenticated'] && store.getters['authStore/isUser'] && store.getters['authStore/isFactor'],
      res => {
        if (res) {
          console.log('res ::', res)
          this.connectToSocket()
          // this.socketListener()
        } else {
          console.log('res ::', res)
          this.disconnectFromSocket()
        }
      },
    )
    if (store.getters['authStore/token'] && localStorage.getItem('userData')) {
      // this.autoplayVideoSettingFirst()
    }
  },
  mounted() {
    // this.fetchData()
    setInterval(this.checkExpiredJwt, 1000)
    // this.autoplayVideoSettingFirst()

    // AWS CLI 연결
    const AWS = require('aws-sdk')
    AWS.config.update({
      region: 'ap-northeast-2',
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
    })
    const sns = new AWS.SNS()
    const s3 = new AWS.S3()
    Vue.prototype.$sns = sns
    Vue.prototype.$s3 = s3
    // console.log(this.$sns)

    // Alarms 수신 기록 확인
    // this.isAlarmsHave()
  },
  methods: {
    ...authStore.mapActions({
      checkExpiredJwt: CHECK_EXPIRED_JWT,
    }),
    // ...settingsStore.mapActions({
    //   $fetchSetAlarmsArray: FETCH_SET_ALARMS_ARRAY,
    // }),
    // async fetchData() {
    //   console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
    //   await this.$fetchSetAlarmsArray({
    //     site: this.authSite,
    //   })
    //   console.log('$fetchSetAlarmsArray', this.fetchSetAlarmsArray)
    //   const alarmsData = this.fetchSetAlarmsArray
    //   alarmsData.forEach((alarm) => {
    //     // alarmSettings 객체를 동적으로 업데이트합니다.
    //     this.alarmSettings[alarm.alarmsSound] = {
    //       useYn: alarm.useYn === 'Y', // 'Y'면 true, 그 외는 false로 변환
    //       maxPlayCount: alarm.alarmsCnt,
    //       playInterval: alarm.alarmsInterval,
    //     }
    //   })
    //   console.log('alarmsSettings : ', this.alarmSettings)
    // },
    connectToSocket() {
      this.socket = io(process.env.VUE_APP_WS_ENDPOINT, {
        query: {
          // token: store.getters['authStore/token'],
          connectSite: this.$site,
          site: JSON.parse(localStorage.getItem('userData')).site,
          role: JSON.parse(localStorage.getItem('userData')).role,
          userid: JSON.parse(localStorage.getItem('userData')).userid,
        },
      })
      Vue.prototype.$socket = this.socket

      this.socket.on('connect', () => {
        console.log('Server Connected')
        // this.socket.emit('enterRoom', 'admin', JSON.parse(localStorage.getItem('userData')).site)
        const roomParam = {
          userType: JSON.parse(localStorage.getItem('userData')).role,
          site: JSON.parse(localStorage.getItem('userData')).site,
        }
        this.socket.emit('enterRoom', roomParam)
      })
    },
    disconnectFromSocket() {
      this.socket.disconnect()
      this.socket = null
      console.log('Server Disconnected')
    },
    // autoplayVideoSettingFirst() {
    //   // 오디오 컨텍스트 생성
    //   Swal.fire({
    //     title: '사운드 자동재생 켜짐',
    //     text: `브라우저 정책에 의한 팝업입니다.
    //            \n 확인버튼 클릭시 사운드 재생이 활성화됩니다.`,
    //     icon: 'info',
    //     showCancelButton: false,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: '확인',
    //     background: 'rgb(3,11,37)',
    //     customClass: {
    //       cancelButton: 'btn btn-dark',
    //       confirmButton: 'btn btn-primary',
    //     },
    //   })
    // },
    // autoplayVideo(target) {
    //   this.audio = new Audio(`https://agress-assets.s3.ap-northeast-2.amazonaws.com/sounds/${target}`)
    //   this.audio.addEventListener('ended', this.replaySound) // 음악이 끝나면 재생 횟수를 체크하여 다시 시작
    //   const result = this.audio.play()
    //   this.isPlaying = true
    //   return result
    // },
    // replaySound() {
    //   this.playCount += 1 // Increase play count
    //   if (this.playCount < this.maxPlayCount) {
    //     this.audio.play() // Play again
    //   } else {
    //     this.stopSound()
    //   }
    // },
    // stopSound() {
    //   this.audio.pause()
    //   this.audio.currentTime = 0
    //   this.audio.removeEventListener('ended', this.replaySound)
    //   this.audio = null
    //   this.isPlaying = false
    // },
    // autoplayVideo(alarmType) {
    //   const settings = this.alarmSettings[alarmType]
    //   console.log('setting : ', settings)
    //
    //   if (settings && settings.useYn) {
    //     this.setupSound(alarmType, settings)
    //   }
    // },
    // setupSound(alarmType, settings) {
    //   this.audio = new Audio(`https://agress-assets.s3.ap-northeast-2.amazonaws.com/sounds/${alarmType}.mp3`)
    //   this.audio.addEventListener('ended', () => this.replaySound(settings))
    //   const result = this.audio.play()
    //   this.isPlaying = true
    //   return result
    // },
    // replaySound(settings) {
    //   this.playCount += 1
    //   console.log('settings.playInterval :: ', settings.playInterval)
    //   if (this.playCount < settings.maxPlayCount) {
    //     setTimeout(() => {
    //       this.audio.play()
    //     }, settings.playInterval * 1000)
    //   } else {
    //     this.stopSound()
    //   }
    // },
    // stopSound() {
    //   if (this.audio) {
    //     this.audio.pause()
    //     this.audio.currentTime = 0
    //     this.audio.removeEventListener('ended', this.replaySound)
    //     this.audio = null
    //     this.isPlaying = false
    //   }
    // },
    // alarmMsg(target) {
    //   const msg = {
    //     id: Math.floor(Math.random() * 1000) + 1,
    //     title: target.title,
    //     avatar: target.avatar,
    //     subtitle: target.subtitle,
    //     type: target.type,
    //     route: target.route,
    //   }
    //   store.dispatch(target.target, msg)
    // },
    // cashFormat(value) {
    //   return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    // },
    // socketListener() {
    //   // Socket 리스너 설정
    //   console.log('listener on')
    //
    //   // 1.충전 알람
    //   this.$socket.on('requestDepositToAdmin', data => {
    //     console.log('Socket: requestDepositToAdmin')
    //     // 사운드를 실행합니다.
    //     this.playCount = 0 // reset play count
    //     // this.autoplayVideo('충전 요청이 있습니다.mp3')
    //     this.autoplayVideo('deposit')
    //     // 알람 전송
    //     const target = {
    //       title: `${data.title}`,
    //       avatar: '충전',
    //       subtitle: `${data.username}님 / ${this.cashFormat(data.subtitle)}원`,
    //       type: 'success',
    //       target: 'notification/addNotification',
    //       route: '/payment/deposit-list',
    //     }
    //     this.alarmMsg(target)
    //   })
    //   // 2.환전 알람
    //   this.$socket.on('requestExchangeToAdmin', data => {
    //     // 사운드를 실행합니다.
    //     this.playCount = 0 // reset play count
    //     this.autoplayVideo('exchange')
    //     // 알람 전송
    //     const target = {
    //       title: `${data.title}`,
    //       avatar: '환전',
    //       subtitle: `${data.username}님 / ${this.cashFormat(data.subtitle)}원`,
    //       type: 'danger',
    //       target: 'notification/addNotification',
    //       route: '/payment/exchange-list',
    //     }
    //     this.alarmMsg(target)
    //   })
    //   // 3.회원 문의(쪽지)
    //   this.$socket.on('requestAlarmsMessageAdmin', data => {
    //     console.log('data :: ', data)
    //     // 사운드를 실행합니다.
    //     this.playCount = 0
    //     this.autoplayVideo('message')
    //     // 알람 전송
    //     const target = {
    //       title: data.title,
    //       avatar: '문의',
    //       subtitle: data.subtitle,
    //       type: 'primary',
    //       target: 'notification/addNotification',
    //       route: '/boards/request-alarms',
    //     }
    //     this.alarmMsg(target)
    //   })
    //   // 4.신규 회원가입 알람
    //   this.$socket.on('requestSignupToAdmin', data => {
    //     // 출금 사운드를 실행합니다.
    //     this.playCount = 0
    //     this.autoplayVideo('signUp')
    //
    //     // 알람 전송
    //     const target = {
    //       title: `${data.userid}님 회원가입요청`,
    //       avatar: '신규',
    //       subtitle: `${data.userid}/${data.nickname}님이 회원가입을 요청했습니다.`,
    //       type: 'dark',
    //       target: 'notification/addNotification',
    //       route: '/boards/request-alarms',
    //     }
    //     this.alarmMsg(target)
    //   })
    //   // 5.게임적중 환급 알람
    //   this.$socket.on('requestUserBetConfirmToAdmin', data => {
    //     // 환전 사운드를 실행합니다.
    //     this.playCount = 0 // reset play count
    //     this.autoplayVideo('betConfirm')
    //
    //     // 알람 전송
    //     const target = {
    //       title: data.title,
    //       avatar: '환급',
    //       subtitle: data.subtitle,
    //       type: 'danger',
    //       target: 'notification/addNotification',
    //       route: '/game/refund-hit-result',
    //     }
    //     this.alarmMsg(target)
    //   })
    //   // 6.총판 출금요청 알람
    //   this.$socket.on('requestBranchCashOutToAdmin', data => {
    //
    //     console.log('requestBranchCashOutToAdmin ::: ', data)
    //     // 총판출금 사운드를 실행합니다.
    //     this.playCount = 0 // reset play count
    //     this.autoplayVideo('branchCashOut')
    //
    //     // 알람 전송
    //     const target = {
    //       title: `${data.userid}/${data.cashOutBranchName} 총판 출금요청`,
    //       avatar: '총환',
    //       subtitle: `${this.cashFormat(data.cashOutBranchAmount)}을 총판출금 요청했습니다.`,
    //       type: 'danger',
    //       target: 'notification/addNotification',
    //       route: '/boards/request-alarms',
    //     }
    //     this.alarmMsg(target)
    //   })
    //   // 7.고객 배팅 알람
    //   this.$socket.on('alarmsUserBetHighToAdmin', data => {
    //     // 환전 사운드를 실행합니다.
    //     this.playCount = 0 // reset play count
    //     this.autoplayVideo('betHigh')
    //
    //     // 알람 전송
    //     const target = {
    //       title: data.title,
    //       avatar: '고액',
    //       subtitle: `${data.subtitle}배당/${this.cashFormat(data.etc)}원 고액 배팅`,
    //       type: 'danger',
    //       target: 'notification/addNotification',
    //       route: '/game/refund-hit-result',
    //     }
    //     this.alarmMsg(target)
    //   })
    //   // 8.관리자 공지사항 알람
    //   this.$socket.on('alarmsNoticeToAdmin', data => {
    //     // 환전 사운드를 실행합니다.
    //     this.playCount = 0 // reset play count
    //     this.autoplayVideo('noticeAdmin')
    //
    //     // 알람 전송
    //     const target = {
    //       title: data.title,
    //       avatar: '공지',
    //       subtitle: `${data.subtitle}공지/${(data.site)}공지사항`,
    //       type: 'danger',
    //       target: 'notification/addNotification',
    //       route: '/boards/notice-admin',
    //     }
    //     console.log('target:::::', target)
    //     this.alarmMsg(target)
    //   })
    //   // 9.비회원 문의 알람
    //   this.$socket.on('requestGuestQuestionToAdmin', data => {
    //     // 출금 사운드를 실행합니다.
    //     this.playCount = 0
    //     this.autoplayVideo('guestMessage.mp3')
    //
    //     // 알람 전송
    //     const target = {
    //       title: `${data.userid}님 문의요청`,
    //       avatar: '비회원',
    //       subtitle: `${data.userid}님이 문의요청을 했습니다.`,
    //       type: 'dark',
    //       target: 'notification/addNotification',
    //       route: '/boards/request-alarms',
    //     }
    //     this.alarmMsg(target)
    //   })
    //   // 기타: 상단메뉴 알람 실시간 처리 카운트 공유
    //   this.$socket.on('updateTopMenuNotification', data => {
    //     // 환전 사운드를 실행합니다.
    //     this.playCount = 0 // reset play count
    //     this.autoplayVideo('adminConfirm')
    //
    //     // 알람 전송
    //     const target = {
    //       title: '처리',
    //       avatar: data.type,
    //       subtitle: '',
    //       type: 'danger',
    //       target: 'notification/addNotification',
    //       route: '/boards/notice-admin',
    //     }
    //     this.alarmMsg(target)
    //   })
    // },
    // async sleep(ms) {
    //   const wakeUpTime = Date.now() + ms
    //   while (Date.now() < wakeUpTime) {}
    // },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  data() {
    return {
      socket: null,
      // audio: null, // audio 객체를 저장할 변수
      // isPlaying: false, // 음악이 재생 중인지 여부를 나타내는 변수
      // playCount: 0, // 추가: 재생 횟수를 기록하는 변수
      // maxPlayCount: 2, // 추가: 최대 재생 횟수
      // alarmSettings: [], // alarmSettings 초기화
    }
  },
  computed: {
    // ...settingsStore.mapGetters({
    //   fetchSetAlarmsArray: 'fetchSetAlarmsArray',
    // }),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
