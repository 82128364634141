export const FETCH_GAME_SET_BASIC = 'FETCH_GAME_SET_BASIC'
export const FETCH_GAME_SET_MENU = 'FETCH_GAME_SET_MENU'
export const FETCH_GAME_SET_ITEM = 'FETCH_GAME_SET_ITEM'
export const FETCH_GAME_SET_LEAGUE = 'FETCH_GAME_SET_LEAGUE'
export const FETCH_GAME_SET_TEAM = 'FETCH_GAME_SET_TEAM'
export const FETCH_GAME_SET_ODDS_MARKET = 'FETCH_GAME_SET_ODDS_MARKET'
export const FETCH_GAME_SET_ODDS_CUSTOM = 'FETCH_GAME_SET_ODDS_CUSTOM'
export const FETCH_GAME_SET_COMBINATION = 'FETCH_GAME_SET_COMBINATION'
export const FETCH_CUSTOM_MATCH_BY_ADMIN = 'FETCH_CUSTOM_MATCH_BY_ADMIN'
export const FETCH_GAME_SET_MATCH_BETTING_CUSTOM = 'FETCH_GAME_SET_MATCH_BETTING_CUSTOM'
export const FETCH_GAME_SET_VENDOR = 'FETCH_GAME_SET_VENDOR'
export const FETCH_GAME_CASINO_TRANSACTION = 'FETCH_GAME_CASINO_TRANSACTION'

