import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

const requireAuth = () => (from, to, next) => {
  const isAuthenticated = store.getters['authStore/isAuthenticated']
  const isFactor = store.getters['authStore/isFactor']
  const isUser = store.getters['authStore/isUser']

  if (isAuthenticated && isFactor && isUser) return next()
  next(`/login?returnPath=${from.fullPath}`)
  // next('/login')
}

const requireMobile = () => (from, to, next) => {
  const isMobile = store.getters['authStore/isMobile']
  console.log('isMobile :::', isMobile)
  if (isMobile) return next()
  next(`/login?returnPath=${from.fullPath}`)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // duplicateNavigationPolicy: 'ignore',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home-v1.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/boards/notice-admin',
      name: 'notice-admin',
      component: () => import('@/views/boards/NoticeAdmin.vue'),
      meta: {
        pageTitle: '관리자 공지사항',
        breadcrumb: [
          {
            text: '관리자 공지사항',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/boards/approvalLogs',
      name: 'approval-logs',
      component: () => import('@/views/boards/ApprovalLogs.vue'),
      meta: {
        pageTitle: '변경이력조회',
        breadcrumb: [
          {
            text: '변경이력조회',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/boards/notice-admin/detail/:type/:id',
      name: 'notice-admin-detail',
      component: () => import('@/views/boards/NoticeAdminCreateDetail.vue'),
      meta: {
        pageTitle: '관리자 공지사항 생성',
        breadcrumb: [
          {
            text: '관리자 공지사항 생성',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/settings/basics-settings',
      name: 'basic-settings',
      component: () => import('@/views/settings/BasicSettings.vue'),
      meta: {
        pageTitle: '기본환경설정',
        breadcrumb: [
          {
            text: '기본환경설정',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/settings/rank-settings',
      name: 'rank-settings',
      component: () => import('@/views/settings/RankSettings.vue'),
      meta: {
        pageTitle: '등급관리',
        breadcrumb: [
          {
            text: '등급관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/settings/event-settings',
      name: 'event-settings',
      component: () => import('@/views/settings/EventSettings.vue'),
      meta: {
        pageTitle: '이벤트관리',
        breadcrumb: [
          {
            text: '이벤트관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/settings/account-settings',
      name: 'account-settings',
      component: () => import('@/views/settings/AccountSettings.vue'),
      meta: {
        pageTitle: '계좌관리',
        breadcrumb: [
          {
            text: '계좌관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/settings/ip-settings',
      name: 'ip-settings',
      component: () => import('@/views/settings/IPSettings.vue'),
      meta: {
        pageTitle: 'IP관리',
        breadcrumb: [
          {
            text: 'IP관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/settings/alarms-settings',
      name: 'alarms-settings',
      component: () => import('@/views/settings/AlarmsSettings.vue'),
      meta: {
        pageTitle: '알람관리',
        breadcrumb: [
          {
            text: '알람관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    // 게시판 관리
    {
      path: '/boards/notice-settings',
      name: 'notice-settings',
      component: () => import('@/views/boards/NoticeSettings.vue'),
      meta: {
        pageTitle: '공지사항 관리',
        breadcrumb: [
          {
            text: '공지사항 관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/boards/notice-settings/detail/:type/:id',
      name: 'notice-settings-detail',
      component: () => import('@/views/boards/NoticeSettingsDetail.vue'),
      meta: {
        pageTitle: '공지사항 생성',
        breadcrumb: [
          {
            text: '공지사항 생성',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/boards/faq-settings',
      name: 'faq-settings',
      component: () => import('@/views/boards/FaqSettings.vue'),
      meta: {
        pageTitle: 'FAQ관리',
        breadcrumb: [
          {
            text: 'FAQ관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/boards/popup-settings',
      name: 'popup-settings',
      component: () => import('@/views/boards/PopupSettings.vue'),
      meta: {
        pageTitle: '팝업관리',
        breadcrumb: [
          {
            text: '팝업관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/boards/popup-settings/detail/:type/:id',
      name: 'popup-settings-detail',
      component: () => import('@/views/boards/PopupSettingsDetail.vue'),
      meta: {
        pageTitle: '팝업관리상세',
        breadcrumb: [
          {
            text: '팝업관리상세',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/game-settings',
      name: 'game-settings',
      component: () => import('@/views/game/GameSettings.vue'),
      meta: {
        pageTitle: '게임 설정',
        breadcrumb: [
          {
            text: '게임 설정',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/betting-list',
      name: 'betting-list',
      component: () => import('@/views/game/BettingList.vue'),
      meta: {
        pageTitle: '배팅 리스트 (종합)',
        breadcrumb: [
          {
            text: '배팅 리스트 (종합)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/betting-list-by-match',
      name: 'betting-list-by-match',
      component: () => import('@/views/game/BettingListByMatch.vue'),
      meta: {
        pageTitle: '배팅 리스트 (경기별)',
        breadcrumb: [
          {
            text: '배팅 리스트 (경기별)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/match-manage-betting',
      name: 'match-manage-betting',
      component: () => import('@/views/game/MatchManageBetting.vue'),
      meta: {
        pageTitle: '경기 관리 (발매)',
        breadcrumb: [
          {
            text: '경기 관리 (발매)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/match-manage-result',
      name: 'match-manage-result',
      component: () => import('@/views/game/MatchManageResult.vue'),
      meta: {
        pageTitle: '경기 관리 (결과)',
        breadcrumb: [
          {
            text: '경기 관리 (결과)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/refund-hit-result',
      name: 'refund-hit-result',
      component: () => import('@/views/game/RefundHitResult.vue'),
      meta: {
        pageTitle: '적중 결과',
        breadcrumb: [
          {
            text: '적중 결과',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/custom-match-addon',
      name: 'custom-match-addon',
      component: () => import('@/views/game/CustomMatchAddon.vue'),
      meta: {
        pageTitle: '수동 경기 (등록)',
        breadcrumb: [
          {
            text: '수동 경기 (등록)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/custom-match-result',
      name: 'custom-match-result',
      component: () => import('@/views/game/CustomMatchResult.vue'),
      meta: {
        pageTitle: '수동 경기 (결과)',
        breadcrumb: [
          {
            text: '수동 경기 (결과)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/game-settings-casino',
      name: 'game-settings-casino',
      component: () => import('@/views/game/GameSettingsCasino.vue'),
      meta: {
        pageTitle: '게임 설정 (카지노)',
        breadcrumb: [
          {
            text: '게임 설정 (카지노)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/game/casino-game-transaction',
      name: 'casino-game-transaction',
      component: () => import('@/views/game/CasinoGameTransaction.vue'),
      meta: {
        pageTitle: '카지노 게임 내역',
        breadcrumb: [
          {
            text: '카지노 게임 내역',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/branch/branch-list',
      name: 'branch-list',
      component: () => import('@/views/branch/BranchList.vue'),
      meta: {
        pageTitle: '총판관리',
        breadcrumb: [
          {
            text: '총판관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/branch/branch-create',
      name: 'branch-create',
      component: () => import('@/views/branch/BranchCreate.vue'),
      meta: {
        pageTitle: '총판관리',
        breadcrumb: [
          {
            text: '총판관리',
          },
          {
            text: '총판등록',
            active: true,
          },
        ],
        navActiveLink: 'branch-list',
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/branch/branch-calculate',
      name: 'branch-calculate',
      component: () => import('@/views/branch/BranchCalculateDaily.vue'),
      meta: {
        pageTitle: '총판 일정산관리',
        breadcrumb: [
          {
            text: '총판 일정산관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/branch/branch-calculate-group',
      name: 'branch-calculate-group',
      component: () => import('@/views/branch/BranchCalculateGroup.vue'),
      meta: {
        pageTitle: '총판 그룹별관리',
        breadcrumb: [
          {
            text: '총판 그룹별관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    // {
    //   path: '/branch/branch-monthly',
    //   name: 'branch-monthly',
    //   component: () => import('@/views/branch/BranchMonthly.vue'),
    //   meta: {
    //     pageTitle: '총판 월별현황',
    //     breadcrumb: [
    //       {
    //         text: '총판 월별현황',
    //         active: true,
    //       },
    //     ],
    //   },
    //   beforeEnter: requireAuth(),
    // },
    {
      path: '/apps/users/list',
      name: 'apps-users-list',
      component: () => import('@/views/apps/user/users-list/UsersList.vue'),
      meta: {
        pageTitle: '회원조회',
        breadcrumb: [
          {
            text: '회원조회',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      // path: '/apps/users/view/:id',
      path: '/apps/users/view/',
      name: 'apps-user-view',
      component: () => import('@/views/apps/user/users-view/UserView.vue'),
      meta: {
        pageTitle: '회원조회상세',
        breadcrumb: [
          {
            text: '회원조회상세',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/users/edit/',
      name: 'apps-users-edit',
      component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: '회원정보수정',
        breadcrumb: [
          {
            text: '회원정보수정',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/users/listNow',
      name: 'apps-users-list-now',
      component: () => import('@/views/apps/user/users-list/UsersListNow.vue'),
      meta: {
        pageTitle: '실시간 접속 회원',
        breadcrumb: [
          {
            text: '실시간 접속 회원',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/users/userConnectLogs',
      name: 'users-connect-logs',
      component: () => import('@/views/users/UserConnectLogs.vue'),
      meta: {
        pageTitle: '접속로그조회',
        breadcrumb: [
          {
            text: '접속로그조회',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/users/userSignupList',
      name: 'users-Signup-list',
      component: () => import('@/views/users/UserSignupList.vue'),
      meta: {
        pageTitle: '신규 회원',
        breadcrumb: [
          {
            text: '신규 회원',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/godfather/batch-schedule-logs',
      name: 'batch-schedule-logs',
      component: () => import('@/views/boards/BatchScheduleLogs.vue'),
      meta: {
        pageTitle: '배치 스케줄 로그',
        breadcrumb: [
          {
            text: '배치 스케줄 로그',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/godfather/notice-admin-create',
      name: 'notice-admin-create',
      component: () => import('@/views/boards/NoticeAdminCreate.vue'),
      meta: {
        pageTitle: '관리자 공지사항',
        breadcrumb: [
          {
            text: '관리자 공지사항',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/payment/deposit-list',
      name: 'deposit-list',
      component: () => import('@/views/payment/DepositList.vue'),
      meta: {
        pageTitle: '충전 관리',
        breadcrumb: [
          {
            text: '충전 관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/payment/exchange-list',
      name: 'exchange-list',
      component: () => import('@/views/payment/ExchangeList.vue'),
      meta: {
        pageTitle: '환전 관리',
        breadcrumb: [
          {
            text: '환전 관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/payment/cash-in-out-list',
      name: 'cash-in-out-list',
      component: () => import('@/views/payment/CashInOutList.vue'),
      meta: {
        pageTitle: '충환전 관리',
        breadcrumb: [
          {
            text: '충환전 관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/payment/branch-cashout-list',
      name: 'branch-cashout-list',
      component: () => import('@/views/payment/BranchCashoutList.vue'),
      meta: {
        pageTitle: '총판 출금 관리',
        breadcrumb: [
          {
            text: '총판 출금 관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/messages-send/admin/',
      name: 'messages-send-admin',
      component: () => import('@/views/messages/MessagesSend.vue'),

      meta: {
        pageTitle: '쪽지 발송',
        breadcrumb: [
          {
            text: '쪽지 발송',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/messages-history/user',
      name: 'messages-history-user',
      component: () => import('@/views/messages/MessagesHistoryUser.vue'),
      meta: {
        pageTitle: '쪽지 관리',
        breadcrumb: [
          {
            text: '쪽지내역 (⇄회원)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/messages-history/admin',
      name: 'messages-history-admin',
      component: () => import('@/views/messages/MessagesHistoryAdmin.vue'),
      meta: {
        pageTitle: '쪽지 관리',
        breadcrumb: [
          {
            text: '쪽지내역 (⇄관리자)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/messages/guest',
      name: 'messages-guest',
      component: () => import('@/views/messages/MessagesGuest.vue'),
      meta: {
        pageTitle: '쪽지관리',
        breadcrumb: [
          {
            text: '쪽지내역 ( ⇄ 비회원 )',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/messages-send/godfather',
      name: 'messages-send-godfather',
      component: () => import('@/views/messages/MessagesSend.vue'),
      meta: {
        pageTitle: '쪽지관리',
        breadcrumb: [
          {
            text: '쪽지발송',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    // {
    //   path: '/messages-history/godfather',
    //   name: 'messages-history-godfather',
    //   component: () => import('@/views/messages/MessagesHistoryUser.vue'),
    //   meta: {
    //     pageTitle: '쪽지관리',
    //     breadcrumb: [
    //       {
    //         text: '쪽지내역',
    //         active: true,
    //       },
    //     ],
    //   },
    //   beforeEnter: requireAuth(),
    // },
    {
      path: '/godfather/admin-ip-setting',
      name: 'admin-ip-setting',
      component: () => import('@/views/settings/AdminIPSettings.vue'),
      meta: {
        pageTitle: '어드민 IP관리',
        breadcrumb: [
          {
            text: '어드민 IP관리',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/godfather/site-admin-set-role',
      name: 'site-admin-set-role',
      component: () => import('@/views/settings/SiteAdminSetRole.vue'),
      meta: {
        pageTitle: '관리자 권한설정',
        breadcrumb: [
          {
            text: '관리자 권한설정',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/messages-history-master',
      name: 'messages-history-master',
      component: () => import('@/views/messages/MessagesHistoryMaster.vue'),
      meta: {
        pageTitle: '마스터 쪽지 관리',
        breadcrumb: [
          {
            text: '마스터 쪽지 관리 ( ⇄ 어드민)',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    // With Mobile
    {
      path: '/login-2factor',
      name: 'login-2factor',
      component: () => import('@/views/Login-2Factor.vue'),
      meta: {
        layout: 'full',
      },
      afterEnter: requireMobile(),
    },
    // Without Auth
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login-v1.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
