export const FETCH_SET_BASIC = 'FETCH_SET_BASIC'
export const FETCH_SET_SIGNIN = 'FETCH_SET_SIGNIN'
export const FETCH_SET_DEPOSIT_EXCHANGE = 'FETCH_SET_DEPOSIT_EXCHANGE'
export const FETCH_SET_ETC = 'FETCH_SET_ETC'
export const FETCH_SET_ACCOUNT = 'FETCH_SET_ACCOUNT'
export const FETCH_SET_RANK = 'FETCH_SET_RANK'
export const FETCH_SET_RANK_DETAIL = 'FETCH_SET_RANK_DETAIL'
export const FETCH_SET_RANK_LIMIT = 'FETCH_SET_RANK_LIMIT'
export const FETCH_SET_IP_BLOCK = 'FETCH_SET_IP_BLOCK'
export const FETCH_SET_IP_ADMIN = 'FETCH_SET_IP_ADMIN'
export const FETCH_SET_EVENT = 'FETCH_SET_EVENT'
export const FETCH_SET_EVENT_DEPOSIT = 'FETCH_SET_EVENT_DEPOSIT'
export const FETCH_SET_SIGNUP = 'FETCH_SET_SIGNUP'
export const FETCH_SET_SIGNUP_PROHIBITS = 'FETCH_SET_SIGNUP_PROHIBITS'
export const FETCH_SET_ALARMS = 'FETCH_SET_ALARMS'
export const FETCH_SET_ALARMS_ARRAY = 'FETCH_SET_ALARMS_ARRAY'
export const FETCH_SET_SITE_ADMIN = 'FETCH_SET_SITE_ADMIN'
